import React from "react";
import styles from "./otvod.module.css";

const tabsInfoProductOtvod = [
  {
    id: 1,
    name: "отводы",
    content: [
      {
        label: "Отводы гнутые",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>Отводы гнутые</h2>
            <h3 className={styles.productInfoText}>
              Изготавливаем отводы гнутые по следующим нормативам:
            </h3>
            <br />
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="pdf/ost_36-42-81.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Отводы по ОСТ 36-42-81
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832113-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.13-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832122-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.22-82
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/gost_22793-83.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ГОСТ 22793-83
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832114-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.14-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832123-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.23-82
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/gost_24950-81.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ГОСТ 24950-81
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832115-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.15-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832124-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.24-82
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/gost_22790-89.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ГОСТ 22790-89
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832116-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.16-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832125-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.25-82
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/gost_2412503-89.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 24.125.03-89
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832117-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.17-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/sto_tskti_32101-2009.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по СТО ЦКТИ 321.01-2009
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/gost_2412504-89.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 24.125.04-89
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832118-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.18-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/sto_tskti_32102-2009.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по СТО ЦКТИ 321.02-2009
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/ost_34-10-421-90.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 34-10-421-90
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832119-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.19-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/sto_tskti_32105-2009.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по СТО ЦКТИ 321.05-2009
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="/pdf/ost.pdf" target="_blank">
                      {" "}
                      Отводы по ОСТ 108.321.11-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_10832120-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Отводы по ОСТ 108.321.20-82
                    </a>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="pdf/ost_10832112-82.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Отводы по ОСТ 108.321.12-82
                    </a>
                  </td>
                  <td>
                    <a
                      href="pdf/ost_1083212182.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Отводы по ОСТ 108.321.21-82
                    </a>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p>
              Радиус гиба гнутых отводов варьируется от 2 до 20 Ду. Угол изгиба
              в соответствии с ГОСТ 22793-83 начинается с показателя 15˚, а
              заканчивают модельный ряд отводы с углом 90˚. В то же время
              компания ООО "СПЕЦЭНЕРГОСТАЛЬ" предлагает своим заказчикам
              изготовление гнутых отводов с углами поворота, начиная от 5˚ и с
              градацией через 1˚
            </p>
            <p>
              В зависимости от толщины стенки и использованной для производства
              марки стали гнутые стальные отводы рассчитаны на рабочее давление
              до 32 МПа и температурный режим в диапазоне от -70˚ до +650˚. В
              качестве материала для изготовления данного типа отводов
              используются фрагменты стальных бесшовных труб соответствующего
              диаметра из углеродистой или низколегированной стали. Производство
              гнутых стальных отводов осуществляют одним из двух способов:
            </p>
            <ul>
              <li>гнутьем в холодном состоянии (ГО);</li>
              <li>горячим гнутьем (ОГ)</li>
            </ul>

            <p>
              При этом геометрические параметры холодногнутых отводов достаточно
              ограничены: с Ду219 до Ду1420, а угол загиба не может превышать
              27˚. При использовании горячего метода угол поворота отвода может
              быть практически любым без ущерба качественным характеристикам
              основного материала.
            </p>
            <br />
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>По типу исполнения отводы могут быть:</th>
                </tr>
                <tr>
                  <td>I — имеющие неравные прямые участки</td>
                </tr>
                <tr>
                  <td>II — с равновеликими прямыми участкам </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 108.321.11÷25-82</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90°; P=37,27 МПа; Т=280°С.
                  </td>
                  <td>
                    Для трубопроводов питательной воды тепловых электростанций
                  </td>
                </tr>
                <tr>
                  <td>СТО ЦКТИ 321.02-2009</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90°; P= до 23,54 МПа; Т= до
                    450°С.
                  </td>
                  <td>
                    Для трубопроводов пара и горячей воды тепловых станций
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 108.327.01÷03-82</td>
                  <td>Угол гиба θ=90°; P= до 37,27 МПа; Т= до 440°С.</td>
                  <td>
                    Для трубопроводов пара и горячей воды тепловых
                    электростанций
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22793-83</td>
                  <td>
                    Угол гиба ϕ=15°,30°,45°,60°,90°; Ру св.10 до 100 МПа(св. 100
                    до 1000 кгс/см²); Dy от 6 мм до 200 мм; Т= от -50°С до
                    +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22817-83</td>
                  <td>
                    Угол гиба ϕ=15°;30°;45°;60°;90° с резьбовыми фланцами; Ру
                    св.10 до 100 МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 200
                    мм; Т= от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22795-83</td>
                  <td>
                    Угол гиба ϕ=90° с резьбовыми фланцами и опорой; Ру св.10 до
                    100 МПа(св. 100 до 1000 кгс/см²); Dy от 40 мм до 200 мм; Т=
                    от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22796-83</td>
                  <td>
                    Угол гиба ϕ=90° с резьбовыми фланцами и опорой; Ру св.10 до
                    100 МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 200 мм; Т=
                    от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22798-83</td>
                  <td>
                    Двойные колена с резьбовыми фланцами; Ру св.10 до 100
                    МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 125 мм; Т= от
                    -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22818-83</td>
                  <td>
                    Угол гиба ϕ=90° с опорами; Ру св.10 до 100 МПа(св. 100 до
                    1000 кгс/см²); Dy от 6 мм до 200 мм; Т= от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22819-83</td>
                  <td>
                    Двойные колена с резьбовыми фланцами: Ру св.10 до 100
                    МПа(св. 100 до 1000 кгс/см²): Dy от 6 мм до 125 мм; Т= от
                    -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label: "Отводы стальные ГОСТ 17375",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы ГОСТ 17375 от производителя
            </h2>
            <p>
              Изготовление отводов бесшовных в соответствии с ГОСТ 17375-01
              осуществляется по двум вариантам исполнения:
            </p>
            <ul>
              <li>DN 15 — 1000 мм; D 21,3 – 1016 мм</li>
              <li>DN 25 — 800 мм; D 32 – 820 мм</li>
            </ul>
            <p>
              Для производства отводов по первому варианту наиболее часто
              применяют марки стали TS4, TS9, Р5-Р43, Е24-1, Е24-2, по второму —
              10, 20, 17Г1С, 10Г2С1, 15ГС, 09Г2С, 12Х18н10т и прочие марки
              углеродистых и низколегированных сталей, в зависимости от
              особенностей эксплуатации и исходя их технического задания,
              предоставленного заказчиком.
            </p>
            <p>
              Стальные бесшовные отводы по ГОСТ 17375-2001 изготавливаются при
              помощи метода штамповки или горячей протяжки. Стандартные углы
              изгиба — 30˚, 45˚, 60˚, 90˚, 180˚, но в случае необходимости в
              нашей компании вы можете заказать и купить отводы с любым иным
              углом изгиба в соответствии с вашими технологическими
              потребностями.
            </p>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th> Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 17375-2001</td>
                  <td>
                    Угол гиба ϕ=45°,60°,90°,180° Ру до 32 МПа( до 320 кгс/см²)
                    Dy от 15 мм до 1000 мм; Т= от -70°С до +450°С.
                  </td>
                  <td>
                    Применяют для трубопроводов различного назначения, включая
                    подконтрольные органам надзора
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22793-83</td>
                  <td>
                    Угол гиба ϕ=15°,30°,45°,60°,90°; Ру св.10 до 100 МПа(св. 100
                    до 1000 кгс/см²); Dy от 6 мм до 200 мм; Т= от -50°С до
                    +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22817-83</td>
                  <td>
                    Угол гиба ϕ=15°,30°,45°,60°,90°C с резьбовыми фланцами; Ру
                    св.10 до 100 МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 200
                    мм; Т= от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22795-83</td>
                  <td>
                    Угол гиба ϕ=90° с резьбовыми фланцами и опорой; Ру св.10 до
                    100 МПа(св. 100 до 1000 кгс/см²); Dy от 40 мм до 200 мм; Т=
                    от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22796-83</td>
                  <td>
                    Угол гиба ϕ=90° с резьбовыми фланцами и опорой; Ру св.10 до
                    100 МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 200 мм; Т=
                    от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22798-83</td>
                  <td>
                    Двойные колена с резьбовыми фланцами; Ру св.10 до 100
                    МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 125 мм; Т= от
                    -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22818-83</td>
                  <td>
                    Угол гиба ϕ=90° с опорами; Ру св.10 до 100 МПа(св. 100 до
                    1000 кгс/см²); Dy от 6 мм до 200 мм; Т= от -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22819-83</td>
                  <td>
                    Двойные колена с резьбовыми фланцами; Ру св.10 до 100
                    МПа(св. 100 до 1000 кгс/см²); Dy от 6 мм до 125 мм; Т= от
                    -50°С до +510°С.
                  </td>
                  <td>
                    Для предприятий нефтехимической промышленности и для
                    производства минеральных удобрений
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label: "Отводы крутоизогнутые бесшовные",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы крутоизогнутые бесшовные
            </h2>
            <h3 className={styles.productInfoText}>
              Технические особенности крутоизогнутых отводов
            </h3>
            <br />
            <p>
              Крутоизогнутые отводы могут быть изготовлены из низколегированной
              или углеродистой стали типа 3D по ГОСТ 17375-01 с R ≈ 1,5 DN, либо
              из стали типа 2D с R ≈ DN по ГОСТ 30753-2001. Помимо этого, для
              нужд отдельных специфических видов производства мы выпускаем
              отводы по ТУ 1400-001-25588891-2015 из легированных и
              высоколегированных сталей (15Х5М-У и подобных).
            </p>
            <br />
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 30753-01</td>
                  <td>
                    R гиба ≈ DN; Угол гиба θ = 45°,60°;90°,180°; D от 57 мм до
                    820 мм
                  </td>
                  <td>
                    Для трубопроводов различного назначения, включая
                    подконтрольные органам надзора
                  </td>
                </tr>
                <tr>
                  <td>ТУ 1400-001-25588891-2015</td>
                  <td>
                    R гиба =1D и 1,5D; Угол гиба θ = 45°,60°,90°,180°; D от 45
                    мм до 630 мм.
                  </td>
                  <td>
                    Для трубопроводов различного назначения, включая
                    подконтрольные органам надзора
                  </td>
                </tr>
                <tr>
                  <td>ТУ 1400-001-25588891-2015</td>
                  <td>Угол гиба от 45°до 90°; D от 530 мм; Pраб. до 32 МПа.</td>
                  <td>
                    Для промысловых трубопроводов , в системах поддерживания
                    пластового давления, транспортирующих среды повышенной
                    коррозийной активности, в условиях северной климатической
                    зоны
                  </td>
                </tr>
                <tr>
                  <td>ТУ 1400-001-25588891-2015</td>
                  <td>
                    Угол гиба от 45°до 90°; D 57 мм - 1440 мм; Pраб. до 32 МПа.
                  </td>
                  <td>
                    Для эксплуатации в условиях умеренного климата, для
                    трубопроводов различного назначения.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label: "Отводы гнутые для трубопроводов ТЭС",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы гнутые для трубопроводов ТЭС
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 108.321.11÷25-82</td>
                  <td>Угол гиба θ=15°,30°,45°,60°,90°; P=37,27 МПа; Т=280°С</td>
                  <td>
                    Для трубопроводов питательной воды тепловых электростанций
                  </td>
                </tr>
                <tr>
                  <td>СТО ЦКТИ 321.02-2009</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90° P= до 23,54 МПа; Т= до
                    450°С.
                  </td>
                  <td>
                    Для трубопроводов пара и горячей воды тепловых станций
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 108.327.01÷03-82</td>
                  <td>Угол гиба θ=90° P= до 37,27 МПа; Т= до 440°С.</td>
                  <td>
                    Для трубопроводов пара и горячей воды тепловых
                    электростанций
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label: "Отводы сварные для трубопроводов АЭС",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы гнутые для трубопроводов АЭС
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th> Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 34-42-661-84</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90°; Рy до 4 МПа; Т= до 350°С; D
                    от 10 до 80 мм.
                  </td>
                  <td>
                    Для трубопроводов атомных электростанций. Соответствует
                    требованиям «Правил АЭС».
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34-42-832-86</td>
                  <td>
                    С приварными фланцами, подлежащие внутреннему
                    антикоррозионному покрытию; Угол гиба θ=45°,60°,90°; Ру=0,6
                    МПа, 1 МПа; Т= до 80°С; D от 50 до 600 мм.
                  </td>
                  <td>
                    Для трубопроводов водоподготовки тепловых электростанций.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.699-97</td>
                  <td>
                    Угол гиба θ=30°,45°,60°,90°; Рраб до 2.2 МПа; Траб до 425°С;
                    D от 40 до 600 мм.
                  </td>
                  <td>
                    Для трубопроводов тепловых и атомных электростанций, включая
                    трубопроводы групп В и С, АС.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34-42-663-84</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90°; Ру= до 4 МПа; Т= до 350°С;
                    D от 10 до 80 мм.
                  </td>
                  <td>
                    Для трубопроводов второго контура атомных электростанций и
                    соответствует требованиям «Правил АЭС».
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 36-21-77</td>
                  <td>
                    Rгиба=1,5Dу; Угол гиба θ=30°,45°,60° и 90°; D от 500 до 1400
                    мм; Pу до 2,5 МПа (≈25 кгс/см2); Т= от -30°С до +300°С;
                  </td>
                  <td>
                    Для технологических трубопроводов, в том числе трубопроводов
                    на предприятиях химической, нефтехимической, нефтяной,
                    металлургической, пищевой, легкой и других отраслей
                    промышленности.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.752-97</td>
                  <td>
                    Угол гиба θ=30°,45°,60° и 90°; D от 100 до 1600 мм; Рраб до
                    2,2 МПа (≈22 кгс/см2); T≤425°С.
                  </td>
                  <td>Для трубопроводов тепловых электростанций.</td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.750-97</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60° и 90°; D от 10 до 400 мм; Рраб
                    до 2,5 МПа (≈25 кгс/см2); T≤200°С.
                  </td>
                  <td>Для трубопроводов тепловых электростанций.</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label: "Вставки кривые ГОСТ 24950-81",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы гнутые и вставки кривые
            </h2>
            <p>
              Наша компания предлагает к поставке гнутые отводы и кривые
              вставки, предназначенные для выполнения поворотов в вертикальной
              или горизонтальной плоскости линейной части стальных магистральных
              трубопроводов и ответвлений от них, изготовляемые на трубогибочном
              оборудовании способом поперечной гибки труб в холодном и горячем
              состоянии
            </p>
            <br />
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th> Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 24950-81</td>
                  <td>
                    R=15, 20, 25, 35, 40, 60 м; Угол гибки от 1° до 27°; D от
                    200 до 1400 мм.
                  </td>
                  <td>Для магистральных трубопроводов и ответвлений от них</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label:
          "Отводы для магистральных и промысловых газопроводов, газонефтепроводов",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы для магистральных и промысловых газопроводов,
              газонефтепроводов
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <td>Стандарт</td>
                  <td>Техническая характеристика</td>
                  <td>Область применения</td>
                </tr>
                <tr>
                  <td>ТУ 1400-001-25588891-2015</td>
                  <td>Угол гиба от 45°до 90°; D от 530 мм; Pраб. до 32 МПа.</td>
                  <td>
                    Для промысловых трубопроводов , в системах поддерживания
                    пластового давления, транспортирующих среды повышенной
                    коррозийной активности, в условиях северной климатической
                    зоны
                  </td>
                </tr>
                <tr>
                  <td>ТУ 1400-001-25588891-2015</td>
                  <td>
                    D от 325 мм до 1420 мм; R гиба = 1.5, 2.0, 2.5, 3.5, 5.0 и
                    10.0 Dy; Р до 10,0 МПа.
                  </td>
                  <td>
                    Для эксплуатации в условиях умеренного климата, для
                    трубопроводов различного назначения.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label: "Отводы повышенной коррозионной активности",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы повышенной коррозионной активности
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ТУ 1400-001-25588891-2015</td>
                  <td>
                    R гиба =1D и 1,5D; Угол гиба θ = 45°,60°,90°,180°; D от 45
                    мм до 630 мм.
                  </td>
                  <td>
                    Для трубопроводов различного назначения, включая
                    подконтрольные органам Госгортехнадзора
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },

      {
        label:
          "Отводы гнутые для трубопроводов с неагрессивными средами и средами содержащих сероводород",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Отводы гнутые для трубопроводов с неагрессивными средами и средами
              содержащих сероводород
            </h2>
            <p>
              Наша компания предлагает гнутые отводы, изготовленные с
              использованием индукционного нагрева. Изделия предназначены для
              магистральных, технологических трубопроводов и других объектов,
              транспортирующих нефть и нефтепродукты, а также иные среды, с
              рабочим давлением в трубопроводе до 14 МПа (140 кгс/см²). Отводы
              изготавливаются из труб с материалами классов прочности: К42, К48,
              К50; К52, К54, К56, К60, К65.
            </p>
            <br />
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 34-42-661-84</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90°; Рy до 4 МПа; Т= до 350°С; D
                    от 10 до 80 мм.
                  </td>
                  <td>
                    Для трубопроводов атомных электростанций. Соответствует
                    требованиям «Правил АЭС».
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34-42-832-86</td>
                  <td>
                    С приварными фланцами, подлежащие внутреннему
                    антикоррозионному покрытию; Угол гиба θ=45°,60°,90°; Ру=0,6
                    МПа, 1 МПа; Т= до 80°С; D от 50 до 600 мм.
                  </td>
                  <td>
                    Для трубопроводов водоподготовки тепловых электростанций.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.699-97</td>
                  <td>
                    Угол гиба θ=30°,45°,60°,90°; Рраб до 2.2 МПа; Траб до 425°С;
                    D от 40 до 600 мм.
                  </td>
                  <td>
                    Для трубопроводов тепловых и атомных электростанций, включая
                    трубопроводы групп В и С, АС.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34-42-663-84</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60°,90°; Ру= до 4 МПа; Т= до 350°С;
                    D от 10 до 80 мм.
                  </td>
                  <td>
                    Для трубопроводов второго контура атомных электростанций и
                    соответствует требованиям «Правил АЭС».
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 36-21-77</td>
                  <td>
                    Rгиба=1,5Dу; Угол гиба θ=30°,45°,60° и 90°; D от 500 до 1400
                    мм; Pу до 2,5 МПа (≈25 кгс/см2); Т= от -30°С до +300°С;
                  </td>
                  <td>
                    Для технологических трубопроводов, в том числе трубопроводов
                    на предприятиях химической, нефтехимической, нефтяной,
                    металлургической, пищевой, легкой и других отраслей
                    промышленности.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.752-97</td>
                  <td>
                    Угол гиба θ=30°,45°,60° и 90°; D от 100 до 1600 мм; Рраб до
                    2,2 МПа (≈22 кгс/см2); T≤425°С.
                  </td>
                  <td>Для трубопроводов тепловых электростанций.</td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.750-97</td>
                  <td>
                    Угол гиба θ=15°,30°,45°,60° и 90°; D от 10 до 400 мм; Рраб
                    до 2,5 МПа (≈25 кгс/см2); T≤200°С.
                  </td>
                  <td>Для трубопроводов тепловых электростанций.</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },

  {
    id: 2,
    name: "переходы",
    content: [
      {
        label: "Переходы",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>Переходы</h2>
            <h3 className={styles.productInfoText}>Разновидности переходов</h3>
            <br />
            <p>
              Марку стали, используемую для изготовления переходов, выбирают,
              учитывая качественные характеристики рабочей среды. Для газа,
              нефти, нефтепродуктов и прочих сред с низкой и средней
              агрессивностью предпочтительными являются углеродистые и
              низколегированные стали Ст3, 10, 20 и т.п. Если же рабочая среда
              характеризуется высокой агрессивностью, то применяют легированные
              или же высоколегированные стали — 12Х18Н10Т, 5ХМ, 15Х5М и др.
            </p>
            <p>
              По типу исполнения переходы могут быть бесшовными (штампованными
              либо литыми), или же сварными листовыми. Стальные переходы
              рассчитаны на рабочие среды с условным давлением до 16 МПа и
              диапазоном температур от -70˚С до +450˚С. По конструктивным
              особенностям различают два вида стальных переходов:
            </p>
            <ul>
              <li>
                Концентрические переходы - по форме представляющие собой
                усеченный конус, и используемые преимущественно на вертикальных
                отрезках трубопроводов
              </li>
              <li>
                Эксцентрические переходы - в сечении они имеют прямоугольный
                треугольник, а монтируют их при воздушной или подземной
                прокладке горизонтальных участков
              </li>
            </ul>
            <br />
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 108.318.13÷25-82</td>
                  <td>
                    Обжатые переходы с абсолютным давлением Р=23,54 МПа;
                    Т=250°С; Dу = от 100´65 до 350´300 мм
                  </td>
                  <td>
                    Для трубопроводов питательной воды тепловых электростанций.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.700-97</td>
                  <td>Р до 2.2 МПа; Т до 425°С; Dу = от 40 до 400 мм.</td>
                  <td>
                    Для трубопроводов тепловых и атомных электростанций, включая
                    трубопроводы групп В и С, АС.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.753-97</td>
                  <td>
                    Р = до 2,5 МПа при Т= до 200°С; Р = до 2,2 МПа при Т= до
                    425°С; Dу = от 530 мм до 1620 мм.
                  </td>
                  <td>Для трубопроводов тепловых электростанций.</td>
                </tr>
                <tr>
                  <td>ОСТ 36-22-77</td>
                  <td>
                    Dу = от 500 мм до 1400 мм; P до 2,5 МПа (≈ 25 кгс/см2); Т=
                    от минус 30 до +300°С.
                  </td>
                  <td>
                    Для предприятий химической, нефтехимической, нефтяной,
                    металлургической, пищевой, легкой и других отраслей
                    промышленности.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 108.450.102-82</td>
                  <td>Р до 9,81 МПа; Т до 540°С; Dу= 80х6,5 мм.</td>
                  <td>Для трубопроводов тепловых электростанций.</td>
                </tr>
                <tr>
                  <td>ГОСТ 17378-01</td>
                  <td>
                    Dy = от 32 до 500 мм; Р до 10 МПа ( до 100 кгс/см2); Т = от
                    минус 70 до 450 °С.
                  </td>
                  <td>Для соединения труб технологических трубопроводов.</td>
                </tr>
                <tr>
                  <td>ГОСТ 22806-83</td>
                  <td>
                    Р до 10 МПа ( до 100 кгс/см2); Т = от минус 50 до 510°С; Dy
                    = от 10 мм до 200 мм.
                  </td>
                  <td>
                    С резьбовыми фланцами для трубопроводов с линзовым
                    уплотнением. Для применения на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22826-83</td>
                  <td>
                    Р= св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); Dy= от 10´6
                    мм до 200´150 мм; Т= от минус 50 до + 510°С.
                  </td>
                  <td>
                    Для трубопроводов, применяемых на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },

  {
    id: 3,
    name: "тройники",
    content: [
      {
        label: "Тройники бесшовные",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>Тройники бесшовные</h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 17376-2001</td>
                  <td>
                    Ру до 10 МПа ( до 100 кгс/см2); Т от минус 70 до 450 °С; Dy
                    от 45 мм до 426 мм.
                  </td>
                  <td>Для соединения труб технологических трубопроводов.</td>
                </tr>
                <tr>
                  <td>ГОСТ 22801-83</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от 6х6
                    до 200х200 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов с линзовым уплотнением, применяемых на
                    предприятиях отраслей нефтехимической промышленности и для
                    производства минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22802-83</td>
                  <td>
                    Ру 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от 40х6 мм
                    до 200х32 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов с линзовым уплотнением, применяемых на
                    предприятиях отраслей нефтехимической промышленности и для
                    производства минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22804-83</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от
                    6х10 до 150х200 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов с линзовым уплотнением, применяемых на
                    предприятиях отраслей нефтехимической промышленности и для
                    производства минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22805-83</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от
                    32х6 до 200х50 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов с линзовым уплотнением, применяемых на
                    предприятиях отраслей нефтехимической промышленности и для
                    производства минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22822-83</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от 6х6
                    до 200х200 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов, применяемых на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22823-83</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от
                    40х6 до 200х32 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов, применяемых на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22824-83 точеные</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от
                    10х6 до 200х150 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов, применяемых на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22825-83 точеные</td>
                  <td>
                    Ру св. 10 до 100 МПа (св. 100 до 1000 кгс/см2); DyxDy от
                    32х6 до 200х50 мм; Т от минус 50 до плюс 510 °С.
                  </td>
                  <td>
                    Для трубопроводов, применяемых на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
      {
        label: "Тройники для трубопроводов и паропроводов ТЭС",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Тройники для трубопроводов и паропроводов ТЭС
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 108.104.01-20-82</td>
                  <td>
                    Ру ≥ 4.0 МПа (40 кгс/см2 ); Т= 280 °С; Dу = 100 и 200 мм.
                  </td>
                  <td>
                    Для трубопроводов питательной воды тепловых электростанций
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 108.720.01--82</td>
                  <td>
                    Р = 37,27 МПа (380 кгс/см2), Т = 280 °С; Р = 23,54 МПа (240
                    кгс/см2), Т = 250 °С; Р = 18,14 МПа (185 кгс/см2), Т = 215
                    °С; Dу = от 10 до 65 мм.
                  </td>
                  <td>
                    Для трубопроводов питательной воды тепловых электростанций
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
      {
        label: "Тройники сварные для трубопроводов АЭС",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Тройники сварные для трубопроводов АЭС
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 34-42-675-678-84</td>
                  <td>
                    Р раб до 2,2 МПа (22 кгс/см2); Т ≤ 350 °С; Dy 100-1600 мм.
                  </td>
                  <td>
                    Для трубопроводов второго контура атомных электростанций
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.762-97</td>
                  <td>
                    Рраб до 2,5 МПа (25 кгс/см2); Т ≤ 200 °С; Dy 65-1600 мм.
                  </td>
                  <td>
                    Для применения на трубопроводах, на которые распространяются
                    РД 03-94
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.764-97</td>
                  <td>
                    Рраб до 2,2 МПа (22 кгс/см2); Т≤ 425 °С; Dy 80-1600 мм.
                  </td>
                  <td>
                    Для применения на трубопроводах, на которые распространяются
                    РД 03-94
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 36-24-77</td>
                  <td>
                    Ру ≤ 2,5 МПа (≈ 25 кгс/см2); Т от -30 до +300 °С; Dy
                    500-1400 мм
                  </td>
                  <td>
                    Для технологических трубопроводов в том числе трубопроводов
                    на предприятиях химической, нефтехимической, нефтяной,
                    металлургической, пищевой, легкой и других отраслей
                    промышленности.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
      {
        label: "Тройники точеные для трубопроводов АЭС",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              Тройники точеные для трубопроводов АЭС
            </h2>
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ОСТ 34-42-675-678-84</td>
                  <td>
                    Р раб до 2,2 МПа (22 кгс/см2); Т ≤ 350 °С; Dy 100-1600 мм.
                  </td>
                  <td>
                    Для трубопроводов второго контура атомных электростанций
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.762-97</td>
                  <td>
                    Рраб до 2,5 МПа (25 кгс/см2); Т ≤ 200 °С; Dy 65-1600 мм.
                  </td>
                  <td>
                    Для применения на трубопроводах, на которые распространяются
                    РД 03-94
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34.10.764-97</td>
                  <td>
                    Рраб до 2,2 МПа (22 кгс/см2); Т≤ 425 °С; Dy 80-1600 мм.
                  </td>
                  <td>
                    Для применения на трубопроводах, на которые распространяются
                    РД 03-94
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 36-24-77</td>
                  <td>
                    Ру ≤ 2,5 МПа (≈ 25 кгс/см2); Т от -30 до +300 °С; Dy
                    500-1400 мм
                  </td>
                  <td>
                    Для технологических трубопроводов в том числе трубопроводов
                    на предприятиях химической, нефтехимической, нефтяной,
                    металлургической, пищевой, легкой и других отраслей
                    промышленности.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },

  {
    id: 4,
    name: "фланцы",
    content: [
      {
        label: "Фланцы",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>фланцы</h2>
            <p>
              Фланцы используют попарно (комплектом). Различаются по размерам
              (плоские и воротниковые фланцы), способу крепления и форме
              уплотнительной поверхности.
            </p>
            <br />
            <p>Фланцы могут иметь следующее исполнение:</p>
            <br />
            <ul>
              <li>с соединительным выступом</li>
              <li>с выступом</li>
              <li>с впадиной</li>
              <li>с шипом</li>
              <li>с пазом</li>
              <li>под линзовую прокладку</li>
              <li>под прокладку овального сечения</li>
              <li>с шипом под фторопластовую прокладку</li>
              <li>с пазом под фторопластовую прокладку</li>
            </ul>
            <br />
            <p>
              Форма уплотнительной поверхности фланца в трубопроводах зависит от
              давления среды, профиля и материала прокладки:
            </p>
            <br />
            <ul>
              <li>
                Гладкие уплотнительные поверхности с прокладками из картона,
                резины и паронита применяются при давлениях до 4 МПа (40 бар)
              </li>
              <li>
                Поверхности с выступом на одном фланце и впадиной на другом с
                асбо-металлическими и паронитовыми прокладками — при давлениях
                до 20 МПа (200 бар)
              </li>
              <li>
                Фланец с конической уплотнительной поверхностью — при давлениях
                выше 6,4 МПа (64 бар).
              </li>
            </ul>
            <br />
            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 33259-2015</td>
                  <td>Ру до 25 МПа (250 кгс/см2); DN = от 10 мм до 4000 мм.</td>
                  <td>
                    Для трубопроводной арматуры, соединительных частей и
                    трубопроводов, машин, оборудования, приборов, патрубков,
                    аппаратов и резервуаров.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },

  {
    id: 5,
    name: "заглушки",
    content: [
      {
        label: "Заглушки",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>Заглушки</h2>

            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 17379. Эллиптические заглушки</td>
                  <td>
                    Ру до 10 МПа ( до 100 кгс/см2); T= от -70° до +450°С; Dy= от
                    25 до 250 мм.
                  </td>
                  <td>Для соединения труб технологических трубопроводов.</td>
                </tr>
                <tr>
                  <td>ГОСТ 22815-83. Фланцевые заглушки</td>
                  <td>
                    Ру от 10 до 100 МПа ( до 1000 кгс/см2); T= от -50° до
                    +510°С; Dy= от 6 до 200 мм.
                  </td>
                  <td>
                    Для трубопроводов с линзовым уплотнением, применяемых на
                    предприятиях отраслей нефтехимической промышленности и для
                    производства минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ГОСТ 22816-83. Фланцевые заглушки</td>
                  <td>
                    Ру от 10 до 40 МПа ( до 400 кгс/см2); T= от -50° до +510°С,
                    Dy = от 6 до 200 мм.
                  </td>
                  <td>
                    Для трубопроводов с линзовым уплотнением, применяемых на
                    предприятиях отраслей нефтехимической промышленности и для
                    производства минеральных удобрений.
                  </td>
                </tr>
                <tr>
                  <td>ОСТ 34-42-666-84. Плоские приварные заглушки</td>
                  <td>Рраб до 2.2 МПа; Траб до 350°С; D= от 50 до 1000 мм.</td>
                  <td>Для трубопроводов атомных электростанций.</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },

  {
    id: 6,
    name: "угольники точёные",
    content: [
      {
        label: "Угольники точёные для трубопроводов",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <h2 className={styles.productInfoTextH2}>
              угольники точёные для трубопроводов
            </h2>

            <h3 className={styles.productInfoText}>
              Нормативно-техническая документация
            </h3>
            <table className={styles.iksweb}>
              <tbody>
                <tr>
                  <th>Стандарт</th>
                  <th>Техническая характеристика</th>
                  <th>Область применения</th>
                </tr>
                <tr>
                  <td>ГОСТ 22820-83</td>
                  <td>
                    Ру= св. 10 до 10МПа(св. 100 до 1000кгс/см²); Dy= от 6 мм до
                    200 мм; Т = от минус 50 до плюс 510°С.
                  </td>
                  <td>
                    Предназначены для применения на предприятиях отраслей
                    нефтехимической промышленности и для производства
                    минеральных удобрений.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ],
  },

  {
    id: 7,
    name: "днища",
    content: [
      {
        label: "Днища",
        contentLeft: (
          <div className={styles.productInfoContentLeft}>
            <p className={styles.productInfoText}>Днища ГОСТ 17379-2001</p>
            <br />
            <p className={styles.productInfoText}>Днища ТУ 102-488-05</p>
            <br />
            <p className={styles.productInfoText}>Днища ГОСТ 6533-78</p>
            <br />
          </div>
        ),
      },
    ],
  },

  {
    id: 8,
    name: "запорная арматура",
    content: [
      {
        label: "Задвижки стальные литые клиновые PN 1,6 МПа",
        subspecies: [
          {
            subspeciesLabel: "30с41нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с41нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 30с41нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек стальных 30с41нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек стальных 30с41нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: сталь 45, латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо):
                    паронит, ТРГ
                  </li>
                  <li>Набивка сальника: АГИ, ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек стальных фланцевых 30с41нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>Наименование</th>
                      <th>DN</th>
                      <th>D</th>
                      <th>D0</th>
                      <th>L</th>
                      <th>H</th>
                      <th>H1</th>
                      <th>Масса, кг</th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 50 </td>
                      <td>50</td>
                      <td>160</td>
                      <td>160</td>
                      <td>180</td>
                      <td>281</td>
                      <td>346</td>
                      <td>17</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 80</td>
                      <td>80</td>
                      <td>195</td>
                      <td>160</td>
                      <td>210</td>
                      <td>336</td>
                      <td>428</td>
                      <td>28,7</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 100</td>
                      <td>100</td>
                      <td>215</td>
                      <td>210</td>
                      <td>230</td>
                      <td>385</td>
                      <td>496</td>
                      <td>39,2</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 125</td>
                      <td>125</td>
                      <td>245</td>
                      <td>210</td>
                      <td>255</td>
                      <td>470</td>
                      <td>600</td>
                      <td>61</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 150</td>
                      <td>150</td>
                      <td>280</td>
                      <td>280</td>
                      <td>280 </td>
                      <td>576</td>
                      <td>742</td>
                      <td>85</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 200</td>
                      <td>200</td>
                      <td>335</td>
                      <td>310</td>
                      <td>330</td>
                      <td>686</td>
                      <td>902</td>
                      <td>123,9</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 250 </td>
                      <td>250</td>
                      <td>405</td>
                      <td>400</td>
                      <td>450</td>
                      <td>854</td>
                      <td>1118</td>
                      <td>240,7</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 300</td>
                      <td>300</td>
                      <td>460</td>
                      <td>460</td>
                      <td>500</td>
                      <td>998</td>
                      <td>1315</td>
                      <td>322,8</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 350 </td>
                      <td>350</td>
                      <td>520</td>
                      <td>460</td>
                      <td>550</td>
                      <td>1220</td>
                      <td>1570</td>
                      <td>361</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с41нж DN 400</td>
                      <td>400</td>
                      <td>580</td>
                      <td>480</td>
                      <td>600</td>
                      <td>1360</td>
                      <td>1770</td>
                      <td>491,7</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с541нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с541нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с541нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с541нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с541нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: сталь 45, латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо):
                    паронит, ТРГ
                  </li>
                  <li>Набивка сальника: АГИ, ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с541нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>Наименование</th>
                      <th>DN</th>
                      <th>D</th>
                      <th>L</th>
                      <th>H3</th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 350</td>
                      <td>350</td>
                      <td>520</td>
                      <td>550</td>
                      <td>1590</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 400</td>
                      <td>400</td>
                      <td>580</td>
                      <td>600</td>
                      <td>1710</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 500</td>
                      <td>500</td>
                      <td>710</td>
                      <td>700</td>
                      <td>2170</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 600</td>
                      <td>600</td>
                      <td>840</td>
                      <td>800</td>
                      <td>2325</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 700</td>
                      <td>700</td>
                      <td>910</td>
                      <td>900</td>
                      <td>2825</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 800</td>
                      <td>800</td>
                      <td>1020</td>
                      <td>1000</td>
                      <td>3480</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 1000</td>
                      <td>1000</td>
                      <td>1255</td>
                      <td>1200</td>
                      <td>4280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с541нж DN 1200</td>
                      <td>1200</td>
                      <td>1485</td>
                      <td>1400</td>
                      <td>5090</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с941нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с941нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с941нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с941нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />

                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с941нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: сталь 45, латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо):
                    паронит, ТРГ
                  </li>
                  <li>Набивка сальника: АГИ, ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с941нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>Наименование</th>
                      <th>DN</th>
                      <th>D</th>
                      <th>L</th>
                      <th>H2</th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 50</td>
                      <td>50</td>
                      <td>160</td>
                      <td>180</td>
                      <td>303</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 80</td>
                      <td>80</td>
                      <td>195</td>
                      <td>210</td>
                      <td>349</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 100</td>
                      <td>100</td>
                      <td>215</td>
                      <td>230</td>
                      <td>399</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 125</td>
                      <td>125</td>
                      <td>245</td>
                      <td>255</td>
                      <td>460</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 150</td>
                      <td>150</td>
                      <td>280</td>
                      <td>280</td>
                      <td>577</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 200</td>
                      <td>200</td>
                      <td>335</td>
                      <td>330</td>
                      <td>690</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 250</td>
                      <td>250</td>
                      <td>405</td>
                      <td>450</td>
                      <td>838</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 300</td>
                      <td>300</td>
                      <td>460</td>
                      <td>500</td>
                      <td>986</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 350</td>
                      <td>350</td>
                      <td>520</td>
                      <td>550</td>
                      <td>1205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 400</td>
                      <td>400</td>
                      <td>580</td>
                      <td>600</td>
                      <td>1291</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 500</td>
                      <td>500</td>
                      <td>710</td>
                      <td>700</td>
                      <td>1650</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 600</td>
                      <td>600</td>
                      <td>840</td>
                      <td>800</td>
                      <td>1865</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 700</td>
                      <td>700</td>
                      <td>910</td>
                      <td>900</td>
                      <td>2575</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 800</td>
                      <td>800</td>
                      <td>1020</td>
                      <td>1000</td>
                      <td>2489</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 1000</td>
                      <td>1000</td>
                      <td>1255</td>
                      <td>1200</td>
                      <td>3230</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 1200</td>
                      <td>1200</td>
                      <td>1485</td>
                      <td>1400</td>
                      <td>3835</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30с941нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>Наименование</th>
                      <th>Тип присоединения электропривода</th>
                      <th>Момент, Н*м</th>
                      <th>Количество оборотов шпинделя</th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 50</td>
                      <td>А</td>
                      <td>52</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 80</td>
                      <td>А</td>
                      <td>84</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 100</td>
                      <td>А</td>
                      <td>84</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 125</td>
                      <td>Б</td>
                      <td>110</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 150</td>
                      <td>А</td>
                      <td>100</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 150</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 200</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 250</td>
                      <td>Б</td>
                      <td>280</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 300</td>
                      <td>Б</td>
                      <td>300</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 300</td>
                      <td>В</td>
                      <td>400</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 350</td>
                      <td>В</td>
                      <td>550</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 400</td>
                      <td>В</td>
                      <td>710</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 500</td>
                      <td>В</td>
                      <td>900</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 600</td>
                      <td>Г</td>
                      <td>1700</td>
                      <td>76</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 700</td>
                      <td>Г</td>
                      <td>1800</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 700</td>
                      <td>Г</td>
                      <td>2500</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 800</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>81</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 800</td>
                      <td>Д</td>
                      <td>3500</td>
                      <td>81</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 1000</td>
                      <td>Д</td>
                      <td>4000</td>
                      <td>85</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 1000</td>
                      <td>Д</td>
                      <td>4700</td>
                      <td>101</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 1200</td>
                      <td>Д</td>
                      <td>6300</td>
                      <td>88</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с941нж DN 1200</td>
                      <td>Д</td>
                      <td>7300</td>
                      <td>102</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  {" "}
                  *Задвижки под разные типы приводов изготавливаются по
                  согласованию с заказчиком (при несоответствии максимального
                  момента электропривода номинальному моменту закрытия
                  задвижки).
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс41нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс41нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс41нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс41нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />

                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс41нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс41нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>Наименование</th>
                      <th>DN</th>
                      <th>L</th>
                      <th>D</th>
                      <th>D0</th>
                      <th>H</th>
                      <th>H1</th>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>160</td>
                      <td>281</td>
                      <td>346</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>160</td>
                      <td>336</td>
                      <td>428</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>215</td>
                      <td>210</td>
                      <td>385</td>
                      <td>496</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 125</td>
                      <td>125</td>
                      <td>255</td>
                      <td>245</td>
                      <td>210</td>
                      <td>470</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 150</td>
                      <td>150</td>
                      <td>280</td>
                      <td>280</td>
                      <td>280</td>
                      <td>576</td>
                      <td>742</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 200</td>
                      <td>200</td>
                      <td>330</td>
                      <td>335</td>
                      <td>310</td>
                      <td>686</td>
                      <td>902</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 250</td>
                      <td>250</td>
                      <td>450</td>
                      <td>405</td>
                      <td>400</td>
                      <td>854</td>
                      <td>1118</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>460</td>
                      <td>460</td>
                      <td>998</td>
                      <td>1315</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>520</td>
                      <td>460</td>
                      <td>1220</td>
                      <td>1570</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс41нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>580</td>
                      <td>48</td>
                      <td>1360</td>
                      <td>1770</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс541нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс541нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс541нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс541нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />

                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс541нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Редуктор: конический или цилиндрический</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс541нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>Наименование</th>
                      <th>DN</th>
                      <th>L</th>
                      <th>D</th>
                      <th>H3</th>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>520</td>
                      <td>1590</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>580</td>
                      <td>1870</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>710</td>
                      <td>2170</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 600</td>
                      <td>600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>2325</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 700</td>
                      <td>700</td>
                      <td>900</td>
                      <td>910</td>
                      <td>2825</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 800</td>
                      <td>800</td>
                      <td>1000</td>
                      <td>1020</td>
                      <td>3480</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 1000</td>
                      <td>1000</td>
                      <td>1200</td>
                      <td>1255</td>
                      <td>4280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс541нж DN 1200</td>
                      <td>1200</td>
                      <td>1400</td>
                      <td>1485</td>
                      <td>5090</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс941нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс941нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс941нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс941нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие.
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />

                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс941нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс941нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>H2</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>303</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>349</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>215</td>
                      <td>399</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 125</td>
                      <td>125</td>
                      <td>255</td>
                      <td>245</td>
                      <td>460</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 150</td>
                      <td>150</td>
                      <td>280</td>
                      <td>280</td>
                      <td>577</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 200</td>
                      <td>200</td>
                      <td>330</td>
                      <td>335</td>
                      <td>690</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 250</td>
                      <td>250</td>
                      <td>450</td>
                      <td>405</td>
                      <td>838</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>460</td>
                      <td>986</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>520</td>
                      <td>1205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>580</td>
                      <td>1291</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>710</td>
                      <td>1650</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 600</td>
                      <td>600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>1865</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 700</td>
                      <td>700</td>
                      <td>900</td>
                      <td>910</td>
                      <td>2070</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 800</td>
                      <td>800</td>
                      <td>1000</td>
                      <td>1020</td>
                      <td>2625</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 1000</td>
                      <td>1000</td>
                      <td>1200</td>
                      <td>1255</td>
                      <td>3230</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 1200</td>
                      <td>1200</td>
                      <td>1400</td>
                      <td>1485</td>
                      <td>3835</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30лс941нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>Наименование</span>
                      </td>
                      <td>
                        <span>Тип присоединения электропривода</span>
                      </td>
                      <td>
                        <span>Момент, Н*м</span>
                      </td>
                      <td>
                        <span>Количество оборотов шпинделя</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 50</td>
                      <td>А</td>
                      <td>52</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 80</td>
                      <td>А</td>
                      <td>84</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 100</td>
                      <td>А</td>
                      <td>84</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 125</td>
                      <td>Б</td>
                      <td>110</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 150</td>
                      <td>А</td>
                      <td>100</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 150</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 200</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 250</td>
                      <td>Б</td>
                      <td>280</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 300</td>
                      <td>Б</td>
                      <td>300</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 300</td>
                      <td>В</td>
                      <td>400</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 350&nbsp;</td>
                      <td>В</td>
                      <td>550</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 400</td>
                      <td>В</td>
                      <td>710</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 500</td>
                      <td>В</td>
                      <td>900</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 600</td>
                      <td>Г</td>
                      <td>1700</td>
                      <td>76</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 700</td>
                      <td>Г</td>
                      <td>1800</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 700</td>
                      <td>Г</td>
                      <td>2500</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 800</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>81</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 800</td>
                      <td>Д</td>
                      <td>3500</td>
                      <td>81</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 1000</td>
                      <td>Д</td>
                      <td>4000</td>
                      <td>85</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 1000</td>
                      <td>Д</td>
                      <td>4700</td>
                      <td>101</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 1200</td>
                      <td>Д</td>
                      <td>6300</td>
                      <td>88</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс941нж DN 1200</td>
                      <td>Д</td>
                      <td>7300</td>
                      <td>102</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Задвижки под разные типы приводов изготавливаются по
                  согласованию с заказчиком (при несоответствии максимального
                  момента электропривода номинальному моменту закрытия задвижки.
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30нж41нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30нж41нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30нж41нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30нж41нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, природный газ, нефтепродукты,
                  жидкие, газообразные и химически активные среды, по отношению
                  к которым, материалы применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +600°С </p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />

                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30нж41нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ </li>
                  <li>Крышка: 12Х18Н9ТЛ </li>
                  <li>Клин (диски): 12Х18Н9ТЛ </li>
                  <li>Шпиндель: 12Х18Н9ТЛ</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: 12Х18Н9ТЛ</li>
                  <li>Шпилька, болт: 45Х14Н14В2М</li>
                  <li>Болт откидной: 45Х14Н14В2М</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 04Х19Н9С2</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30нж41нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>H1</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж DN&nbsp;50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>160</td>
                      <td>281</td>
                      <td>346</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>160</td>
                      <td>336</td>
                      <td>428</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>215</td>
                      <td>210</td>
                      <td>385</td>
                      <td>496</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 125</td>
                      <td>125</td>
                      <td>255</td>
                      <td>245</td>
                      <td>210</td>
                      <td>470</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 150</td>
                      <td>150</td>
                      <td>280</td>
                      <td>280</td>
                      <td>320</td>
                      <td>576</td>
                      <td>742</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 200</td>
                      <td>200</td>
                      <td>330</td>
                      <td>335</td>
                      <td>320</td>
                      <td>686</td>
                      <td>902</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 250</td>
                      <td>250</td>
                      <td>450</td>
                      <td>405</td>
                      <td>400</td>
                      <td>854</td>
                      <td>1118</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>460</td>
                      <td>460</td>
                      <td>998</td>
                      <td>1315</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>520</td>
                      <td>460</td>
                      <td>1220</td>
                      <td>1570</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж41нж&nbsp;DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>580</td>
                      <td>480</td>
                      <td>1360</td>
                      <td>1770</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30нж941нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30нж941нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30нж941нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30нж941нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, природный газ, нефтепродукты,
                  жидкие, газообразные и химически активные среды, по отношению
                  к которым, материалы применяемые в задвижке коррозионностойкие{" "}
                </p>
                <p>Температура рабочей среды: от −60°С до +600°С </p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />

                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30нж941нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ </li>
                  <li>Крышка: 12Х18Н9ТЛ </li>
                  <li>Клин (диски): 12Х18Н9ТЛ </li>
                  <li>Шпиндель: 12Х18Н9Т</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: 12Х18Н9Т</li>
                  <li>Шпилька, болт: 45Х14Н14В2М</li>
                  <li>Болт откидной или анкерный: 45Х14Н14В2М</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 04Х19Н9С2 </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30нж941нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>H2</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>303</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>349</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>215</td>
                      <td>399</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 125</td>
                      <td>125</td>
                      <td>255</td>
                      <td>245</td>
                      <td>460</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 150</td>
                      <td>150</td>
                      <td>280</td>
                      <td>280</td>
                      <td>577</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 200</td>
                      <td>200</td>
                      <td>330</td>
                      <td>335</td>
                      <td>690</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 250</td>
                      <td>250</td>
                      <td>450</td>
                      <td>405</td>
                      <td>838</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>460</td>
                      <td>986</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>520</td>
                      <td>1205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>580</td>
                      <td>1291</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>710</td>
                      <td>1650</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30нж941нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>Наименование</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Тип присоединения электропривода</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Момент, Н*м</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Количество оборотов шпинделя</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 50</td>
                      <td>А</td>
                      <td>52</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 80</td>
                      <td>А</td>
                      <td>84</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 100</td>
                      <td>А</td>
                      <td>84</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 125</td>
                      <td>Б</td>
                      <td>110</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 150</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 200</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 250</td>
                      <td>Б</td>
                      <td>280</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 300</td>
                      <td>В</td>
                      <td>400</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 350&nbsp;</td>
                      <td>В</td>
                      <td>550</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 400</td>
                      <td>В</td>
                      <td>710</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж941нж&nbsp;DN 500</td>
                      <td>В</td>
                      <td>900</td>
                      <td>65</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Задвижки под разные типы приводов изготавливаются по
                  согласованию с заказчиком при несоответствии максимального
                  момента электропривода номинальному моменту закрытия задвижки.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "Задвижки стальные литые клиновые PN 2,5 МПа",
        subspecies: [
          {
            subspeciesLabel: "30с64нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с64нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с64нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с64нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек стальных 30с64нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо):
                    паронит
                  </li>
                  <li>Набивка сальника: АГИ, ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек стальных фланцевых 30с64нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>H1</span>
                      </th>
                      <th>
                        <span>Масса, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>160</td>
                      <td>281</td>
                      <td>346</td>
                      <td>17</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>160</td>
                      <td>336</td>
                      <td>428</td>
                      <td>27,9</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>230</td>
                      <td>210</td>
                      <td>385</td>
                      <td>496</td>
                      <td>41,7</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 150</td>
                      <td>150</td>
                      <td>403</td>
                      <td>300</td>
                      <td>310</td>
                      <td>576</td>
                      <td>742</td>
                      <td>98,6</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>360</td>
                      <td>310</td>
                      <td>686</td>
                      <td>902</td>
                      <td>156</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>425</td>
                      <td>400</td>
                      <td>854</td>
                      <td>1118</td>
                      <td>260</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж&nbsp;DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>460</td>
                      <td>998</td>
                      <td>1315</td>
                      <td>357,4</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>550</td>
                      <td>460</td>
                      <td>1220</td>
                      <td>1570</td>
                      <td>465</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с64нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>480</td>
                      <td>1290</td>
                      <td>1708</td>
                      <td>530</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с564нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с564нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с564нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с564нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с564нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо):
                    паронит
                  </li>
                  <li>Набивка сальника: АГИ, ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с564нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>H3</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>1710</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>2170</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;600</td>
                      <td>600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>2325</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;700</td>
                      <td>700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>2825</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;800</td>
                      <td>800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>3480</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;1000</td>
                      <td>1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>4280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с564нж DN&nbsp;1200</td>
                      <td>1200</td>
                      <td>1400</td>
                      <td>1525</td>
                      <td>5190</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с964нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с964нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с964нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с964нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с964нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо):
                    паронит
                  </li>
                  <li>Набивка сальника: АГИ, ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с964нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>H2</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>303</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>349</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>230</td>
                      <td>399</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 150</td>
                      <td>150</td>
                      <td>403</td>
                      <td>300</td>
                      <td>577</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>360</td>
                      <td>690</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>425</td>
                      <td>838</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>986</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>550</td>
                      <td>1205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>1270</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>1545</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 600</td>
                      <td>600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>1665</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 700</td>
                      <td>700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>2070</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 800</td>
                      <td>800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>2625</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 1000</td>
                      <td>1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>3230</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 1200</td>
                      <td>1200</td>
                      <td>1400</td>
                      <td>1525</td>
                      <td>3935</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30с964нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>Наменование</span>
                      </td>
                      <td>
                        <span>Тип присоединения электропривода</span>
                      </td>
                      <td>
                        <span>Момент, Н*м</span>
                      </td>
                      <td>
                        <span>Количество оборотов шпинделя</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 50</td>
                      <td>А</td>
                      <td>52</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 80</td>
                      <td>А</td>
                      <td>84</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 100</td>
                      <td>А</td>
                      <td>100</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 150</td>
                      <td>Б</td>
                      <td>190</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 200</td>
                      <td>Б</td>
                      <td>210</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 250</td>
                      <td>Б</td>
                      <td>280</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 300</td>
                      <td>Б</td>
                      <td>300</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 300</td>
                      <td>В</td>
                      <td>400</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 350</td>
                      <td>В</td>
                      <td>500</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 400</td>
                      <td>В</td>
                      <td>710</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 500</td>
                      <td>Г</td>
                      <td>1200</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 600</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>76</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 700</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 700</td>
                      <td>Д</td>
                      <td>3000</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 800</td>
                      <td>Д</td>
                      <td>2400</td>
                      <td>82</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 800</td>
                      <td>Д</td>
                      <td>3800</td>
                      <td>82</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 1000</td>
                      <td>Д</td>
                      <td>4700</td>
                      <td>85</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 1000</td>
                      <td>Д</td>
                      <td>6700</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 1200</td>
                      <td>Д</td>
                      <td>7900</td>
                      <td>88</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с964нж DN 1200</td>
                      <td>Д</td>
                      <td>10000</td>
                      <td>102</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Задвижки под разные типы приводов изготавливаются по
                  согласованию с заказчиком (при несоответствии максимального
                  момента электропривода номинальному моменту закрытия задвижки.
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс64нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс64нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс64нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс64нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>
                  Температура рабочей среды: от −60°С до +450°С (для
                  жаростойкого исполнения до +600°С)
                </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс64нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс64нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>H1</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>160</td>
                      <td>281</td>
                      <td>346</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN&nbsp;80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>160</td>
                      <td>336</td>
                      <td>428</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>230</td>
                      <td>210</td>
                      <td>385</td>
                      <td>496</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 150</td>
                      <td>150</td>
                      <td>403</td>
                      <td>300</td>
                      <td>310</td>
                      <td>576</td>
                      <td>742</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>360</td>
                      <td>310</td>
                      <td>686</td>
                      <td>902</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>425</td>
                      <td>400</td>
                      <td>854</td>
                      <td>1118</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>460</td>
                      <td>998</td>
                      <td>1315</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>550</td>
                      <td>460</td>
                      <td>1220</td>
                      <td>1570</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс64нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>480</td>
                      <td>1290</td>
                      <td>1708</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс564нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс564нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс564нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс564нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс564нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Редуктор: конический или цилиндрический</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс564нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>H3</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж DN&nbsp;400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>1710</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж&nbsp;DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>2170</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж DN 600</td>
                      <td>600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>2325</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж&nbsp;DN 700</td>
                      <td>700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>2825</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж&nbsp;DN 800</td>
                      <td>800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>3480</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж&nbsp;DN 1000</td>
                      <td>1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>4280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс564нж&nbsp;DN 1200</td>
                      <td>1200</td>
                      <td>1400</td>
                      <td>1525</td>
                      <td>5190</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс964нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс964нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс964нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс964нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие.
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс964нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс964нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>H2</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>303</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>349</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>230</td>
                      <td>399</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 150</td>
                      <td>150</td>
                      <td>403</td>
                      <td>300</td>
                      <td>577</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>360</td>
                      <td>690</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>425</td>
                      <td>838</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>986</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>550</td>
                      <td>1205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>1270</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>1545</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 600</td>
                      <td>600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>1665</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 700</td>
                      <td>700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>2070</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 800</td>
                      <td>800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>2625</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж&nbsp;DN 1000</td>
                      <td>1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>3230</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 1200</td>
                      <td>1200</td>
                      <td>1400</td>
                      <td>1525</td>
                      <td>3935</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30лс964нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>Наименование</span>
                      </td>
                      <td>
                        <span>Тип присоединения электропривода</span>
                      </td>
                      <td>
                        <span>Момент, Н*м</span>
                      </td>
                      <td>
                        <span>Количество оборотов шпинделя</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 50</td>
                      <td>А</td>
                      <td>52</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 80</td>
                      <td>А</td>
                      <td>84</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 100</td>
                      <td>А</td>
                      <td>100</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 150</td>
                      <td>Б</td>
                      <td>190</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 200</td>
                      <td>Б</td>
                      <td>210</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 250</td>
                      <td>Б</td>
                      <td>280</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 300</td>
                      <td>Б</td>
                      <td>300</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 300</td>
                      <td>В</td>
                      <td>400</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 350</td>
                      <td>В</td>
                      <td>500</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 400</td>
                      <td>В</td>
                      <td>710</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 500</td>
                      <td>Г</td>
                      <td>1200</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 600</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>76</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 700</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 700</td>
                      <td>Д</td>
                      <td>3000</td>
                      <td>72</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 800</td>
                      <td>Д</td>
                      <td>2400</td>
                      <td>82</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 800</td>
                      <td>Д</td>
                      <td>3800</td>
                      <td>82</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 1000</td>
                      <td>Д</td>
                      <td>4700</td>
                      <td>85</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 1000</td>
                      <td>Д</td>
                      <td>6700</td>
                      <td>100</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 1200</td>
                      <td>Д</td>
                      <td>7900</td>
                      <td>88</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс964нж DN 1200</td>
                      <td>Д</td>
                      <td>10000</td>
                      <td>102</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Задвижки под разные типы приводов изготавливаются по
                  согласованию с заказчиком (при несоответствии максимального
                  момента электропривода номинальному моменту закрытия
                  задвижки.)
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30нж64нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30нж64нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30нж64нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30нж64нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, природный газ, нефтепродукты,
                  жидкие, газообразные и химически активные среды, по отношению
                  к которым, материалы применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +600°С </p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30нж64нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ </li>
                  <li>Крышка: 12Х18Н9ТЛ </li>
                  <li>Клин (диски): 12Х18Н9ТЛ </li>
                  <li>Шпиндель: 12Х18Н9ТЛ</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: 12Х18Н9Т</li>
                  <li>Шпилька, болт: 45Х14Н14В2М</li>
                  <li>Болт откидной: 45Х14Н14В2М</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 04Х19Н9С2</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30нж64нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>H1</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN&nbsp;50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>160</td>
                      <td>281</td>
                      <td>346</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN&nbsp;80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>160</td>
                      <td>336</td>
                      <td>428</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>230</td>
                      <td>210</td>
                      <td>385</td>
                      <td>496</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 150</td>
                      <td>150</td>
                      <td>403</td>
                      <td>300</td>
                      <td>310</td>
                      <td>576</td>
                      <td>742</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>360</td>
                      <td>310</td>
                      <td>686</td>
                      <td>902</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>425</td>
                      <td>400</td>
                      <td>854</td>
                      <td>1118</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>460</td>
                      <td>998</td>
                      <td>1315</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>550</td>
                      <td>460</td>
                      <td>1220</td>
                      <td>1570</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж64нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>480</td>
                      <td>1291</td>
                      <td>1708</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30нж964нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30нж964нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30нж964нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30нж964нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, природный газ, нефтепродукты,
                  жидкие, газообразные и химически активные среды, по отношению
                  к которым, материалы применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +600°С </p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30нж964нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ </li>
                  <li>Крышка: 12Х18Н9ТЛ </li>
                  <li>Клин (диски): 12Х18Н9ТЛ </li>
                  <li>Шпиндель: 12Х18Н9Т</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: 12Х18Н9Т</li>
                  <li>Шпилька, болт: 45Х14Н14В2М</li>
                  <li>Болт откидной или анкерный: 45Х14Н14В2М</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 04Х19Н9С2 </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30нж964нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>Наименование</span>
                      </td>
                      <td>
                        <span>DN</span>
                      </td>
                      <td>
                        <span>L</span>
                      </td>
                      <td>
                        <span>D</span>
                      </td>
                      <td>
                        <span>H2</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж&nbsp;DN 50</td>
                      <td>50</td>
                      <td>180</td>
                      <td>160</td>
                      <td>303</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 80</td>
                      <td>80</td>
                      <td>210</td>
                      <td>195</td>
                      <td>349</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 100</td>
                      <td>100</td>
                      <td>230</td>
                      <td>230</td>
                      <td>399</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 150</td>
                      <td>150</td>
                      <td>403</td>
                      <td>300</td>
                      <td>577</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>360</td>
                      <td>690</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>425</td>
                      <td>838</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 300</td>
                      <td>300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>986</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 350</td>
                      <td>350</td>
                      <td>550</td>
                      <td>550</td>
                      <td>1205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 400</td>
                      <td>400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>1270</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 500</td>
                      <td>500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30нж964нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>Наименование</span>
                      </td>
                      <td>
                        <span>Тип присоединения электропривода</span>
                      </td>
                      <td>
                        <span>Момент, Н*м</span>
                      </td>
                      <td>
                        <span>Количество оборотов шпинделя</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 50</td>
                      <td>А</td>
                      <td>52</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 80</td>
                      <td>А</td>
                      <td>84</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 100</td>
                      <td>А</td>
                      <td>100</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 150</td>
                      <td>Б</td>
                      <td>190</td>
                      <td>33</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 200</td>
                      <td>Б</td>
                      <td>210</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 250</td>
                      <td>Б</td>
                      <td>280</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 300</td>
                      <td>В</td>
                      <td>400</td>
                      <td>53</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 350</td>
                      <td>В</td>
                      <td>500</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 400</td>
                      <td>В</td>
                      <td>710</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30нж964нж DN 500</td>
                      <td>Г</td>
                      <td>1200</td>
                      <td>65</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Задвижки под разные типы приводов изготавливаются по
                  согласованию с заказчиком при несоответствии максимального
                  момента электропривода номинальному моменту закрытия задвижки.
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "Задвижки стальные литые клиновые PN 4,0 МПа",
        subspecies: [
          {
            subspeciesLabel: "30с15нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с15нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с15нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с15нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек стальных 30с15нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной или анкерный: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с15нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>H1</span>
                      </th>
                      <th>
                        <span>Масса, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 50</td>
                      <td>50</td>
                      <td>216/250</td>
                      <td>160</td>
                      <td>160</td>
                      <td>283</td>
                      <td>345</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 80</td>
                      <td>80</td>
                      <td>283/310</td>
                      <td>195</td>
                      <td>210</td>
                      <td>392</td>
                      <td>486</td>
                      <td>35,8</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 100</td>
                      <td>100</td>
                      <td>305/350</td>
                      <td>230</td>
                      <td>210</td>
                      <td>450</td>
                      <td>565</td>
                      <td>50,8</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 150</td>
                      <td>150</td>
                      <td>403/450</td>
                      <td>300</td>
                      <td>310</td>
                      <td>600</td>
                      <td>768</td>
                      <td>111</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>375</td>
                      <td>400</td>
                      <td>753</td>
                      <td>972</td>
                      <td>200</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>445</td>
                      <td>450</td>
                      <td>1098</td>
                      <td>1368</td>
                      <td>256</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 300</td>
                      <td>300</td>
                      <td>502</td>
                      <td>510</td>
                      <td>500</td>
                      <td>1125</td>
                      <td>1450</td>
                      <td>355</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 350</td>
                      <td>350</td>
                      <td>762</td>
                      <td>570</td>
                      <td>550</td>
                      <td>1204</td>
                      <td>1565</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с15нж Ду 400</td>
                      <td>400</td>
                      <td>838</td>
                      <td>655</td>
                      <td>560</td>
                      <td>—</td>
                      <td>—</td>
                      <td>750</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с515нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с515нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с515нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с515нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с515нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с515нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H3</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с515нж Ду 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>445</td>
                      <td>1420</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с515нж Ду 300</td>
                      <td>300</td>
                      <td>502</td>
                      <td>510</td>
                      <td>1720</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с515нж Ду 350</td>
                      <td>350</td>
                      <td>762</td>
                      <td>570</td>
                      <td>1625</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с515нж Ду 400</td>
                      <td>400</td>
                      <td>838</td>
                      <td>655</td>
                      <td>2040</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с515нж Ду 500</td>
                      <td>500</td>
                      <td>991</td>
                      <td>755</td>
                      <td>2300</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с915нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с915нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с915нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с915нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с915нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин (диски): сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька, болт: сталь 35</li>
                  <li>Болт откидной или анкерный: сталь 35</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с915нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H2</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 50</td>
                      <td>50</td>
                      <td>216/250</td>
                      <td>160</td>
                      <td>302,5</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 80</td>
                      <td>80</td>
                      <td>283/310</td>
                      <td>195</td>
                      <td>396</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 100</td>
                      <td>100</td>
                      <td>305/350</td>
                      <td>230</td>
                      <td>458</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 150</td>
                      <td>150</td>
                      <td>403/450</td>
                      <td>300</td>
                      <td>595</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>375</td>
                      <td>739,5</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>445</td>
                      <td>965</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 300</td>
                      <td>300</td>
                      <td>502</td>
                      <td>510</td>
                      <td>1100</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 350</td>
                      <td>300</td>
                      <td>762</td>
                      <td>570</td>
                      <td>1250</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 400</td>
                      <td>400</td>
                      <td>838</td>
                      <td>655</td>
                      <td>1565</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 500</td>
                      <td>500</td>
                      <td>991</td>
                      <td>755</td>
                      <td>1776</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30с915нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Тип присоединения электропривода</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Момент, Н*м</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Количество оборотов шпинделя</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 50</td>
                      <td>А</td>
                      <td>60</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 80</td>
                      <td>А</td>
                      <td>100</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 100</td>
                      <td>А</td>
                      <td>100</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 100</td>
                      <td>Б</td>
                      <td>110</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 150</td>
                      <td>Б</td>
                      <td>200</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 200</td>
                      <td>Б</td>
                      <td>300</td>
                      <td>37</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 250</td>
                      <td>В</td>
                      <td>460</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 300</td>
                      <td>В</td>
                      <td>570</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 350</td>
                      <td>В</td>
                      <td>950</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 400</td>
                      <td>Г</td>
                      <td>1200</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с915нж Ду 500</td>
                      <td>Г</td>
                      <td>2400</td>
                      <td>64</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс15нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс15нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс15нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс15нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие.
                </p>
                <p>
                  Температура рабочей среды: от −60°С до +450°С (для
                  жаростойкого исполнения до +600°С)
                </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс15нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной или анкерный: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс15нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D0</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H1</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 50</td>
                      <td>50</td>
                      <td>216/250</td>
                      <td>160</td>
                      <td>160</td>
                      <td>283</td>
                      <td>345</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 80</td>
                      <td>80</td>
                      <td>283/310</td>
                      <td>195</td>
                      <td>210</td>
                      <td>392</td>
                      <td>486</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 100</td>
                      <td>100</td>
                      <td>305/350</td>
                      <td>230</td>
                      <td>210</td>
                      <td>450</td>
                      <td>565</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 150</td>
                      <td>150</td>
                      <td>403/450</td>
                      <td>300</td>
                      <td>310</td>
                      <td>600</td>
                      <td>768</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>375</td>
                      <td>400</td>
                      <td>753</td>
                      <td>972</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 250&nbsp;</td>
                      <td>250</td>
                      <td>457</td>
                      <td>445</td>
                      <td>450</td>
                      <td>1098</td>
                      <td>1368</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 300&nbsp;</td>
                      <td>300</td>
                      <td>502</td>
                      <td>510</td>
                      <td>500</td>
                      <td>1217</td>
                      <td>1553</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс15нж Ду 350&nbsp;</td>
                      <td>350</td>
                      <td>762</td>
                      <td>570</td>
                      <td>550</td>
                      <td>1204</td>
                      <td>1565</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс515нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс515нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс515нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс515нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс515нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Редуктор: конический или цилиндрический</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс515нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H3</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс515нж Ду 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>445</td>
                      <td>1420</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс515нж Ду 300</td>
                      <td>300</td>
                      <td>502</td>
                      <td>510</td>
                      <td>1720</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс515нж Ду 350</td>
                      <td>350</td>
                      <td>762</td>
                      <td>570</td>
                      <td>1625</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс515нж Ду 400</td>
                      <td>400</td>
                      <td>838</td>
                      <td>655</td>
                      <td>2040</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс515нж Ду 500</td>
                      <td>500</td>
                      <td>991</td>
                      <td>755</td>
                      <td>2300</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015</p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс915нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс915нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс915нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс915нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие.
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс915нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ </li>
                  <li>Крышка: сталь 20ГЛ </li>
                  <li>Клин (диски): сталь 20ГЛ </li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька, болт: сталь 40Х</li>
                  <li>Болт откидной или анкерный: сталь 40Х</li>
                  <li>
                    Уплотнение между корпусом и крышкой (прокладка, кольцо): ТРГ
                  </li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 07Х25Н13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс915нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H2</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 50</td>
                      <td>50</td>
                      <td>216/250</td>
                      <td>160</td>
                      <td>302,5</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 80</td>
                      <td>80</td>
                      <td>283/310</td>
                      <td>195</td>
                      <td>396</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 100</td>
                      <td>100</td>
                      <td>305/350</td>
                      <td>230</td>
                      <td>458</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 150</td>
                      <td>150</td>
                      <td>403/450</td>
                      <td>300</td>
                      <td>595</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 200</td>
                      <td>200</td>
                      <td>419</td>
                      <td>375</td>
                      <td>739,5</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 250</td>
                      <td>250</td>
                      <td>457</td>
                      <td>445</td>
                      <td>965</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 300</td>
                      <td>300</td>
                      <td>502</td>
                      <td>510</td>
                      <td>1100</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 350</td>
                      <td>350</td>
                      <td>762</td>
                      <td>570</td>
                      <td>1250</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 400</td>
                      <td>400</td>
                      <td>838</td>
                      <td>655</td>
                      <td>1565</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 500</td>
                      <td>500</td>
                      <td>991</td>
                      <td>755</td>
                      <td>1776</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30лс915нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Тип присоединения электропривода</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Момент, Н*м</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Количество оборотов шпинделя</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 50</td>
                      <td>А</td>
                      <td>
                        <p>60</p>
                      </td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 80</td>
                      <td>А</td>
                      <td>100</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 100</td>
                      <td>А</td>
                      <td>100</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 100</td>
                      <td>Б</td>
                      <td>120</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 150</td>
                      <td>Б</td>
                      <td>200</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 200</td>
                      <td>Б</td>
                      <td>300</td>
                      <td>37</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 250</td>
                      <td>В</td>
                      <td>460</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 300</td>
                      <td>В</td>
                      <td>570</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 350</td>
                      <td>В</td>
                      <td>950</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 400</td>
                      <td>Г</td>
                      <td>1200</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс915нж Ду 500</td>
                      <td>Г</td>
                      <td>2400</td>
                      <td>64</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Задвижки стальные литые клиновые PN 6,3 МПа",
        subspecies: [
          {
            subspeciesLabel: "30с76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие{" "}
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с76нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 45</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с76нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D0</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H1</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 50</td>
                      <td>50</td>
                      <td>270</td>
                      <td>175</td>
                      <td>220</td>
                      <td>443</td>
                      <td>513</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 80</td>
                      <td>80</td>
                      <td>321</td>
                      <td>210</td>
                      <td>280</td>
                      <td>539</td>
                      <td>639</td>
                      <td>46</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 100</td>
                      <td>100</td>
                      <td>359</td>
                      <td>250</td>
                      <td>350</td>
                      <td>625</td>
                      <td>745</td>
                      <td>68</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 150</td>
                      <td>150</td>
                      <td>447</td>
                      <td>340</td>
                      <td>430</td>
                      <td>999</td>
                      <td>1169</td>
                      <td>135</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 200</td>
                      <td>200</td>
                      <td>536</td>
                      <td>405</td>
                      <td>490</td>
                      <td>1020</td>
                      <td>1240</td>
                      <td>237</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 250</td>
                      <td>250</td>
                      <td>625</td>
                      <td>470</td>
                      <td>500</td>
                      <td>1218</td>
                      <td>1488</td>
                      <td>408</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с76нж Ду 300</td>
                      <td>300</td>
                      <td>714</td>
                      <td>530</td>
                      <td>600</td>
                      <td>1356</td>
                      <td>1676</td>
                      <td>590</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с576нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с576нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с576нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с576нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с576нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 45</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с576нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H3</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с576нж Ду 250</td>
                      <td>250</td>
                      <td>625</td>
                      <td>470</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с576нж Ду 300</td>
                      <td>300</td>
                      <td>714</td>
                      <td>530</td>
                      <td>1695</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с576нж Ду 350</td>
                      <td>350</td>
                      <td>841</td>
                      <td>595</td>
                      <td>1672</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с576нж Ду 400</td>
                      <td>400</td>
                      <td>867</td>
                      <td>670</td>
                      <td>2010</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с976нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с976нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с976нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с976нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с976нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь углеродистая 45</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с976нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H2</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 50</td>
                      <td>50</td>
                      <td>270</td>
                      <td>175</td>
                      <td>345</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 80</td>
                      <td>80</td>
                      <td>321</td>
                      <td>210</td>
                      <td>420</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 100</td>
                      <td>100</td>
                      <td>359</td>
                      <td>250</td>
                      <td>485</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 150</td>
                      <td>150</td>
                      <td>447</td>
                      <td>340</td>
                      <td>660</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 200</td>
                      <td>200</td>
                      <td>536</td>
                      <td>405</td>
                      <td>800</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 250</td>
                      <td>250</td>
                      <td>625</td>
                      <td>470</td>
                      <td>1130</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 300</td>
                      <td>300</td>
                      <td>714</td>
                      <td>530</td>
                      <td>1335</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 350</td>
                      <td>350</td>
                      <td>841</td>
                      <td>595</td>
                      <td>1301</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 400</td>
                      <td>400</td>
                      <td>867</td>
                      <td>670</td>
                      <td>1550</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30с976нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Тип присоединения электропривода</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Момент, Н*м</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Количество оборотов шпинделя</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 50</td>
                      <td>А</td>
                      <td>50</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 50</td>
                      <td>Б</td>
                      <td>130</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 80</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 100</td>
                      <td>Б</td>
                      <td>200</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 150</td>
                      <td>Б</td>
                      <td>250</td>
                      <td>29</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 200</td>
                      <td>В</td>
                      <td>450</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 250</td>
                      <td>В</td>
                      <td>700</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 300</td>
                      <td>В</td>
                      <td>900</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 300</td>
                      <td>Г</td>
                      <td>1060</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 350</td>
                      <td>Г</td>
                      <td>1300</td>
                      <td>44</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с976нж Ду 400</td>
                      <td>Г</td>
                      <td>1500</td>
                      <td>42</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие{" "}
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс76нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Шпиндель: 08Х18Н10</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35ХМ</li>
                  <li>Шпилька: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс76нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H1</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 50</td>
                      <td>50</td>
                      <td>270</td>
                      <td>175</td>
                      <td>220</td>
                      <td>443</td>
                      <td>513</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 80</td>
                      <td>80</td>
                      <td>321</td>
                      <td>210</td>
                      <td>280</td>
                      <td>539</td>
                      <td>639</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 100</td>
                      <td>100</td>
                      <td>359</td>
                      <td>250</td>
                      <td>350</td>
                      <td>625</td>
                      <td>745</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 150</td>
                      <td>150</td>
                      <td>447</td>
                      <td>340</td>
                      <td>430</td>
                      <td>999</td>
                      <td>1169</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 200</td>
                      <td>200</td>
                      <td>536</td>
                      <td>405</td>
                      <td>490</td>
                      <td>1020</td>
                      <td>1240</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 250</td>
                      <td>250</td>
                      <td>625</td>
                      <td>470</td>
                      <td>500</td>
                      <td>1218</td>
                      <td>1488</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс76нж Ду 300</td>
                      <td>300</td>
                      <td>714</td>
                      <td>530</td>
                      <td>600</td>
                      <td>1356</td>
                      <td>1676</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс576нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс576нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс576нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс576нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс576нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Шпиндель: 08Х18Н10</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35ХМ</li>
                  <li>Шпилька: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: сталь по типу 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс576нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H3</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс576нж Ду 250</td>
                      <td>250</td>
                      <td>625</td>
                      <td>470</td>
                      <td>1130</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс576нж Ду 300</td>
                      <td>300</td>
                      <td>714</td>
                      <td>530</td>
                      <td>1335</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс576нж Ду 350</td>
                      <td>350</td>
                      <td>841</td>
                      <td>595</td>
                      <td>1301</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс576нж Ду 400</td>
                      <td>400</td>
                      <td>867</td>
                      <td>670</td>
                      <td>1550</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс976нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс976нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс976нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс976нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс976нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Шпиндель: 08Х18Н10</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35ХМ</li>
                  <li>Шпилька: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: сталь по типу 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс976нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H2</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 50</td>
                      <td>50</td>
                      <td>270</td>
                      <td>175</td>
                      <td>345</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 80</td>
                      <td>80</td>
                      <td>321</td>
                      <td>210</td>
                      <td>420</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 100</td>
                      <td>100</td>
                      <td>359</td>
                      <td>250</td>
                      <td>485</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 150</td>
                      <td>150</td>
                      <td>447</td>
                      <td>340</td>
                      <td>660</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 200</td>
                      <td>200</td>
                      <td>536</td>
                      <td>405</td>
                      <td>800</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 250</td>
                      <td>250</td>
                      <td>625</td>
                      <td>470</td>
                      <td>1130</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 300</td>
                      <td>300</td>
                      <td>714</td>
                      <td>530</td>
                      <td>1335</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 350</td>
                      <td>350</td>
                      <td>841</td>
                      <td>595</td>
                      <td>1301</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 400</td>
                      <td>400</td>
                      <td>867</td>
                      <td>670</td>
                      <td>1550</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 30лс976нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Тип присоединения электропривода</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Момент, Н*м</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Количество оборотов шпинделя</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 50</td>
                      <td>Б</td>
                      <td>130</td>
                      <td>16</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 80</td>
                      <td>Б</td>
                      <td>160</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 100</td>
                      <td>Б</td>
                      <td>200</td>
                      <td>24</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 150</td>
                      <td>Б</td>
                      <td>250</td>
                      <td>29</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 200</td>
                      <td>В</td>
                      <td>450</td>
                      <td>36</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 250</td>
                      <td>В</td>
                      <td>700</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 300</td>
                      <td>В</td>
                      <td>900</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 300</td>
                      <td>Г</td>
                      <td>1060</td>
                      <td>40</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 350</td>
                      <td>Г</td>
                      <td>1300</td>
                      <td>44</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс976нж Ду 400</td>
                      <td>Г</td>
                      <td>1500</td>
                      <td>42</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Задвижки стальные литые клиновые PN 16 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с45нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с45нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 31с45нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 31с45нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 31с45нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 45</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 31с45нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>H1</span>
                      </th>
                      <th>
                        <span>Масса, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Задвижка 31с45нж Ду 50</td>
                      <td>50</td>
                      <td>300</td>
                      <td>195</td>
                      <td>300</td>
                      <td>435</td>
                      <td>498</td>
                      <td>52</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с45нж Ду 80</td>
                      <td>80</td>
                      <td>390</td>
                      <td>230</td>
                      <td>350</td>
                      <td>481</td>
                      <td>573</td>
                      <td>102</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с45нж Ду 100</td>
                      <td>100</td>
                      <td>450</td>
                      <td>265</td>
                      <td>450</td>
                      <td>784</td>
                      <td>904</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с45нж Ду 150</td>
                      <td>150</td>
                      <td>559</td>
                      <td>350</td>
                      <td>500</td>
                      <td>665</td>
                      <td>820</td>
                      <td>291</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с45нж Ду 200</td>
                      <td>200</td>
                      <td>660</td>
                      <td>430</td>
                      <td>450</td>
                      <td>1262</td>
                      <td>1482</td>
                      <td>442</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с45нж Ду 250</td>
                      <td>250</td>
                      <td>787</td>
                      <td>500</td>
                      <td>450</td>
                      <td>1524</td>
                      <td>1794</td>
                      <td>650</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31с545нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с545нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 31с545нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 31с545нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 31с545нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: латунь ЛС59</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 31с545нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H3</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с545нж Ду 150</td>
                      <td>150</td>
                      <td>559</td>
                      <td>350</td>
                      <td>1200</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с545нж Ду 200</td>
                      <td>200</td>
                      <td>660</td>
                      <td>430</td>
                      <td>1500</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с545нж Ду 250</td>
                      <td>250</td>
                      <td>787</td>
                      <td>500</td>
                      <td>1800</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31с945нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с945нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 31с945нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 31с945нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 31с945нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 45</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 20Х13</li>
                  <li>Наплавка на клине: сталь по типу 20Х13</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 31с945нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H2</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 50</td>
                      <td>50</td>
                      <td>300</td>
                      <td>195</td>
                      <td>290</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 80</td>
                      <td>80</td>
                      <td>390</td>
                      <td>230</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 100</td>
                      <td>100</td>
                      <td>450</td>
                      <td>265</td>
                      <td>535</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 150</td>
                      <td>150</td>
                      <td>559</td>
                      <td>350</td>
                      <td>925</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 200</td>
                      <td>200</td>
                      <td>660</td>
                      <td>430</td>
                      <td>1280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 250</td>
                      <td>250</td>
                      <td>787</td>
                      <td>500</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 31с945нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Тип присоединения электропривода</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Момент, Н*м</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Количество оборотов шпинделя</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 50</td>
                      <td>А</td>
                      <td>100</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 50</td>
                      <td>Б</td>
                      <td>150</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 80</td>
                      <td>Б</td>
                      <td>230</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 100</td>
                      <td>Б</td>
                      <td>250</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 100</td>
                      <td>В</td>
                      <td>300</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 150</td>
                      <td>В</td>
                      <td>680</td>
                      <td>21</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 200</td>
                      <td>В</td>
                      <td>1000</td>
                      <td>26</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 200</td>
                      <td>Г</td>
                      <td>1180</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31с945нж Ду 250</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>32</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс45нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31лс45нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 31лс45нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 31лс45нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие{" "}
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 31лс45нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Шпиндель: 08Х18Н10</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35ХМ</li>
                  <li>Шпилька, болт: сталь 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Маховик: Чугун</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 08Х18Н10</li>
                  <li>Наплавка на клине: сталь по типу 08Х18Н10</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 31лс45нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H1</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс45нж Ду 50</td>
                      <td>50</td>
                      <td>300</td>
                      <td>195</td>
                      <td>300</td>
                      <td>435</td>
                      <td>498</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс45нж Ду 80</td>
                      <td>80</td>
                      <td>390</td>
                      <td>230</td>
                      <td>350</td>
                      <td>481</td>
                      <td>573</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс45нж Ду 100</td>
                      <td>100</td>
                      <td>450</td>
                      <td>265</td>
                      <td>450</td>
                      <td>784</td>
                      <td>904</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс45нж Ду 150</td>
                      <td>150</td>
                      <td>559</td>
                      <td>350</td>
                      <td>500</td>
                      <td>665</td>
                      <td>820</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс45нж Ду 200</td>
                      <td>200</td>
                      <td>660</td>
                      <td>430</td>
                      <td>450</td>
                      <td>1262</td>
                      <td>1482</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс45нж Ду 250</td>
                      <td>250</td>
                      <td>787</td>
                      <td>500</td>
                      <td>450</td>
                      <td>1524</td>
                      <td>1794</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс545нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31лс545нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 31лс545нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 31лс545нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 31лс545нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Шпиндель: 08Х18Н10</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35ХМ</li>
                  <li>Шпилька, болт: 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Редуктор: редуктор конический или цилиндрический</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 08Х18Н10</li>
                  <li>Наплавка на клине: 08Х18Н10</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 31лс545нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H3</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс545нж Ду 150</td>
                      <td>150</td>
                      <td>559</td>
                      <td>350</td>
                      <td>925</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс545нж Ду 200</td>
                      <td>200</td>
                      <td>660</td>
                      <td>430</td>
                      <td>1280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс545нж Ду 250</td>
                      <td>250</td>
                      <td>787</td>
                      <td>500</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс945нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31лс945нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 31лс945нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 31лс945нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 31лс945нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Шпиндель: 08Х18Н10</li>
                  <li>Гайка шпинделя: латунь ЛС59</li>
                  <li>Гайка: сталь 35ХМ</li>
                  <li>Шпилька, болт: 35ХМ</li>
                  <li>Болт откидной: сталь 35ХМ</li>
                  <li>Прокладка: спирально-навитая</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Подшипник: по ГОСТ 7872-89</li>
                  <li>Наплавка на кольце в корпусе: 08Х18Н10</li>
                  <li>Наплавка на клине: 08Х18Н10</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 31лс945нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H2</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 50</td>
                      <td>50</td>
                      <td>300</td>
                      <td>195</td>
                      <td>290</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 80</td>
                      <td>80</td>
                      <td>390</td>
                      <td>230</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 100</td>
                      <td>100</td>
                      <td>450</td>
                      <td>265</td>
                      <td>535</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 150</td>
                      <td>150</td>
                      <td>559</td>
                      <td>350</td>
                      <td>925</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 200</td>
                      <td>200</td>
                      <td>660</td>
                      <td>430</td>
                      <td>1280</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 250</td>
                      <td>250</td>
                      <td>787</td>
                      <td>500</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>
                <p>*Присоединительные размеры фланцев по ГОСТ 33259-2015 </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Характеристики задвижек 31лс945нж для подбора электропривода
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Тип присоединения электропривода</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Момент, Н*м</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Количество оборотов шпинделя</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 50</td>
                      <td>А</td>
                      <td>100</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 50</td>
                      <td>Б</td>
                      <td>150</td>
                      <td>13</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 80</td>
                      <td>Б</td>
                      <td>230</td>
                      <td>19</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 100</td>
                      <td>Б</td>
                      <td>250</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 100</td>
                      <td>В</td>
                      <td>300</td>
                      <td>20</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 150</td>
                      <td>В</td>
                      <td>680</td>
                      <td>21</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 200</td>
                      <td>В</td>
                      <td>1000</td>
                      <td>26</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 200</td>
                      <td>Г</td>
                      <td>1180</td>
                      <td>27</td>
                    </tr>
                    <tr>
                      <td>Задвижка 31лс945нж Ду 250</td>
                      <td>Г</td>
                      <td>2000</td>
                      <td>32</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label:
          "Задвижки стальные клиновые литые с невыдвижным шпинделем PN 2,5 МПа",
        subspecies: [
          {
            subspeciesLabel: "30с527нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с527нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с527нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с527нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>
                  Температурный диапазон транспортировки рабочих сред задвижек
                  климатического исполнения У1 ГОСТ 15150-69: от −40°С до +450°С
                </p>
                <p>Условия эксплуатации: У1</p>
                <p>
                  Температурный диапазон эксплуатации задвижек климатического
                  исполнения У1 ГОСТ 15150-69: от −40°С до +45°С{" "}
                </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с527нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Стойка крышки: сталь 25Л</li>
                  <li>Крышка сальника: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59-1</li>
                  <li>Прокладка: паронит, ПУТГ</li>
                  <li>Редуктор</li>
                  <li>Прокладка: паронит, ПУТГ </li>
                  <li>Сальник: сальниковая набивка кольца ТРГ</li>
                  <li>Кольцо подсальниковое: латунь ЛС59-1</li>
                  <li>Втулка: латунь ЛС59-1</li>
                  <li>Втулка: латунь ЛС59-1</li>
                  <li>Кольцо: сталь 25</li>
                  <li>Шпилька: сталь 35</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька: сталь 35 </li>
                  <li>Гайка: сталь 25 </li>
                  <li>Блок анкерный: сталь 35</li>
                  <li>Гайка: сталь 25</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с527нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>&nbsp;D2&nbsp;</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>n</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>d</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>h</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Н</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>b</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>430</td>
                      <td>390</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>&nbsp;1000</td>
                      <td>36</td>
                      <td>375</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>550</td>
                      <td>505</td>
                      <td>16</td>
                      <td>33</td>
                      <td>4</td>
                      <td>&nbsp;1300</td>
                      <td>44</td>
                      <td>540</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>660</td>
                      <td>615</td>
                      <td>20</td>
                      <td>39</td>
                      <td>4</td>
                      <td>&nbsp;1365</td>
                      <td>48</td>
                      <td>900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>770</td>
                      <td>720</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>&nbsp;1512</td>
                      <td>51</td>
                      <td>1400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>875</td>
                      <td>820</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;1875</td>
                      <td>55</td>
                      <td>1900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>990</td>
                      <td>930</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;2072</td>
                      <td>59</td>
                      <td>2400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с527нж Ду 1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>1210</td>
                      <td>1140</td>
                      <td>28</td>
                      <td>56</td>
                      <td>5</td>
                      <td>&nbsp;2435</td>
                      <td>63</td>
                      <td>4600&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30с927нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30с927нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30с927нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30с927нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>
                  Температурный диапазон транспортировки рабочих сред задвижек
                  климатического исполнения У1 ГОСТ 15150-69: от −40°С до +450°С
                </p>
                <p>Условия эксплуатации: У1</p>
                <p>
                  Температурный диапазон эксплуатации задвижек климатического
                  исполнения У1 ГОСТ 15150-69: от −40°С до +45°С{" "}
                </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30с927нж
                </h3>
                <ol>
                  <li>Корпус: сталь 25Л</li>
                  <li>Крышка: сталь 25Л</li>
                  <li>Клин: сталь 25Л</li>
                  <li>Стойка крышки: сталь 25Л</li>
                  <li>Крышка сальника: сталь 25Л</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Гайка шпинделя: латунь ЛС59-1</li>
                  <li>Прокладка: паронит, ПУТГ</li>
                  <li>Редуктор</li>
                  <li>Прокладка: паронит, ПУТГ </li>
                  <li>Сальник: сальниковая набивка кольца ТРГ</li>
                  <li>Кольцо подсальниковое: латунь ЛС59-1</li>
                  <li>Втулка: латунь ЛС59-1</li>
                  <li>Втулка: латунь ЛС59-1</li>
                  <li>Кольцо: сталь 25</li>
                  <li>Шпилька: сталь 35</li>
                  <li>Гайка: сталь 25</li>
                  <li>Шпилька: сталь 35 </li>
                  <li>Гайка: сталь 25 </li>
                  <li>Блок анкерный: сталь 35</li>
                  <li>Гайка: сталь 25</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30с927нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>Наименование</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>L</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>D</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>D1</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>&nbsp;D2&nbsp;</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>n</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>d</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>h</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>Н</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>b</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>Масса, кг</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>430</td>
                      <td>390</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>&nbsp;1000</td>
                      <td>36</td>
                      <td>335</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>550</td>
                      <td>505</td>
                      <td>16</td>
                      <td>33</td>
                      <td>4</td>
                      <td>&nbsp;1300</td>
                      <td>44</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>660</td>
                      <td>615</td>
                      <td>20</td>
                      <td>39</td>
                      <td>4</td>
                      <td>&nbsp;1365</td>
                      <td>48</td>
                      <td>900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>770</td>
                      <td>720</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>&nbsp;1512</td>
                      <td>51</td>
                      <td>1400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>875</td>
                      <td>820</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;1875</td>
                      <td>55</td>
                      <td>1900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>990</td>
                      <td>930</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;2072</td>
                      <td>59</td>
                      <td>2400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30с927нж Ду 1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>1210</td>
                      <td>1140</td>
                      <td>28</td>
                      <td>56</td>
                      <td>5</td>
                      <td>&nbsp;2435</td>
                      <td>63</td>
                      <td>4600&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс527нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс527нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс527нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс527нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>
                  Температурный диапазон транспортировки рабочих сред задвижек
                  климатического исполнения У1 ГОСТ 15150-69: от −60°С до +450°С
                </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>
                  Температурный диапазон эксплуатации задвижек климатического
                  исполнения У1 ГОСТ 15150-69: от −60°С до +45°С{" "}
                </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс527нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин: сталь 20ГЛ</li>
                  <li>Стойка крышки: сталь 20ГЛ</li>
                  <li>Крышка сальника: сталь 20ГЛ</li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59-1</li>
                  <li>Прокладка: паронит, ПУТГ</li>
                  <li>Редуктор</li>
                  <li>Прокладка: паронит, ПУТГ </li>
                  <li>Сальник: сальниковая набивка кольца ТРГ</li>
                  <li>Кольцо подсальниковое: латунь ЛС59-1</li>
                  <li>Втулка: латунь ЛС59-1</li>
                  <li>Втулка: латунь ЛС59-1</li>
                  <li>Кольцо: сталь 35Х</li>
                  <li>Шпилька: сталь 40Х</li>
                  <li>Гайка: сталь 35Х</li>
                  <li>Шпилька: сталь 40Х </li>
                  <li>Гайка: сталь 35Х </li>
                  <li>Блок анкерный: сталь 40Х</li>
                  <li>Гайка: сталь 35Х</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс527нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td width="250">
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>&nbsp;D2&nbsp;</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Н</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>b</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td width="210">Задвижка 30лс527нж Ду 300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>430</td>
                      <td>390</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>&nbsp;1000</td>
                      <td>36</td>
                      <td>375</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс527нж Ду 400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>550</td>
                      <td>505</td>
                      <td>16</td>
                      <td>33</td>
                      <td>4</td>
                      <td>&nbsp;1300</td>
                      <td>44</td>
                      <td>540</td>
                    </tr>
                    <tr>
                      <td width="300">Задвижка 30лс527нж Ду 500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>660</td>
                      <td>615</td>
                      <td>20</td>
                      <td>39</td>
                      <td>4</td>
                      <td>&nbsp;1365</td>
                      <td>48</td>
                      <td>900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс527нж Ду 600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>770</td>
                      <td>720</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>&nbsp;1512</td>
                      <td>51</td>
                      <td>1400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс527нж Ду 700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>875</td>
                      <td>820</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;1875</td>
                      <td>55</td>
                      <td>1900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс527нж Ду 800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>990</td>
                      <td>930</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;2072</td>
                      <td>59</td>
                      <td>2400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс527нж Ду1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>1210</td>
                      <td>1140</td>
                      <td>28</td>
                      <td>56</td>
                      <td>5</td>
                      <td>&nbsp;2435</td>
                      <td>63</td>
                      <td>4600 &nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30лс927нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30лс927нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30лс927нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30лс927нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>
                <p>
                  Температурный диапазон транспортировки рабочих сред задвижек
                  климатического исполнения У1 ГОСТ 15150-69: от −60°С до +450°С
                </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>
                  Температурный диапазон эксплуатации задвижек климатического
                  исполнения У1 ГОСТ 15150-69: от −60°С до +45°С{" "}
                </p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30лс927нж
                </h3>
                <ol>
                  <li>Корпус: сталь 20ГЛ</li>
                  <li>Крышка: сталь 20ГЛ</li>
                  <li>Клин : сталь 20ГЛ</li>
                  <li>Стойка крышки: сталь 20ГЛ</li>
                  <li>Крышка сальника: сталь 20ГЛ</li>
                  <li>Шпиндель: 14Х17Н2</li>
                  <li>Гайка шпинделя: латунь ЛС59-1</li>
                  <li>Прокладка: паронит, ПУТГ</li>
                  <li>Редуктор</li>
                  <li> Прокладка: паронит, ПУТГ </li>
                  <li> Сальник: сальниковая набивка кольца ТРГ</li>
                  <li> Кольцо подсальниковое: латунь ЛС59-1</li>
                  <li> Втулка: латунь ЛС59-1</li>
                  <li> Втулка: латунь ЛС59-1</li>
                  <li> Кольцо: сталь 35Х</li>
                  <li> Шпилька: сталь 40Х</li>
                  <li> Гайка: сталь 35Х</li>
                  <li> Шпилька: сталь 40Х</li>
                  <li> Гайка: сталь 35Х</li>
                  <li> Блок анкерный: сталь 40Х</li>
                  <li> Гайка: сталь 35Х </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30лс927нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>&nbsp;D2&nbsp;</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>n</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>d</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>h</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Н</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>b</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду 300</td>
                      <td>500</td>
                      <td>485</td>
                      <td>430</td>
                      <td>390</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>&nbsp;1000</td>
                      <td>36</td>
                      <td>335</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду 400</td>
                      <td>600</td>
                      <td>610</td>
                      <td>550</td>
                      <td>505</td>
                      <td>16</td>
                      <td>33</td>
                      <td>4</td>
                      <td>&nbsp;1300</td>
                      <td>44</td>
                      <td>500</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду 500</td>
                      <td>700</td>
                      <td>730</td>
                      <td>660</td>
                      <td>615</td>
                      <td>20</td>
                      <td>39</td>
                      <td>4</td>
                      <td>&nbsp;1365</td>
                      <td>48</td>
                      <td>900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду 600</td>
                      <td>800</td>
                      <td>840</td>
                      <td>770</td>
                      <td>720</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>&nbsp;1512</td>
                      <td>51</td>
                      <td>1400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду 700</td>
                      <td>900</td>
                      <td>960</td>
                      <td>875</td>
                      <td>820</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;1875</td>
                      <td>55</td>
                      <td>1900</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду 800</td>
                      <td>1000</td>
                      <td>1075</td>
                      <td>990</td>
                      <td>930</td>
                      <td>24</td>
                      <td>45</td>
                      <td>5</td>
                      <td>&nbsp;2072</td>
                      <td>59</td>
                      <td>2400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30лс927нж Ду1000</td>
                      <td>1200</td>
                      <td>1315</td>
                      <td>1210</td>
                      <td>1140</td>
                      <td>28</td>
                      <td>56</td>
                      <td>5</td>
                      <td>&nbsp;2435</td>
                      <td>63</td>
                      <td>4600&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Задвижки с обрезиненным клином 30ч39р 30ч539р 30ч939р",
        subspecies: [
          {
            subspeciesLabel: "30ч39р",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>30ч39р</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30ч39р
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30ч39р
                </h3>
                <br />
                <p>Давление PN: 1,0 и 1,6 МПа (10 и 16 кгс/см2)</p>
                <p>
                  Рабочая среда: Вода, пар, неагрессивные жидкие и газообразные
                  среды, по отношению к которым, материалы применяемые в
                  задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от -30 до +130 °С</p>
                <p>Температура окружающего воздуха: от −30°С до +45°С</p>
                <p>Класс герметичности по ГОСТ 9544-2005: "А"</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30ч39р
                </h3>
                <ol>
                  <li>Корпус: Высокопрочный чугун ВЧ50</li>
                  <li>
                    Обрезиненный клин: EPDM (основание — высокопрочный чугун
                    ВЧ50)
                  </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек 30ч39р PN 1,0 МПа
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>T</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 50</td>
                      <td>150</td>
                      <td>165</td>
                      <td>125</td>
                      <td>102</td>
                      <td>4</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 65</td>
                      <td>170</td>
                      <td>185</td>
                      <td>145</td>
                      <td>122</td>
                      <td>4</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>228</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 80</td>
                      <td>180</td>
                      <td>200</td>
                      <td>160</td>
                      <td>133</td>
                      <td>4</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>265</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 100</td>
                      <td>190</td>
                      <td>220</td>
                      <td>180</td>
                      <td>158</td>
                      <td>8</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>300</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 125</td>
                      <td>200</td>
                      <td>250</td>
                      <td>210</td>
                      <td>184</td>
                      <td>8</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>355</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 150</td>
                      <td>210</td>
                      <td>285</td>
                      <td>240</td>
                      <td>212</td>
                      <td>8</td>
                      <td>22</td>
                      <td>3</td>
                      <td>19</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 200</td>
                      <td>230</td>
                      <td>340</td>
                      <td>295</td>
                      <td>268</td>
                      <td>8</td>
                      <td>22</td>
                      <td>3</td>
                      <td>20</td>
                      <td>490</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 250</td>
                      <td>250</td>
                      <td>405</td>
                      <td>350</td>
                      <td>320</td>
                      <td>12</td>
                      <td>22</td>
                      <td>3</td>
                      <td>22</td>
                      <td>585</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 300</td>
                      <td>270</td>
                      <td>460</td>
                      <td>400</td>
                      <td>370</td>
                      <td>12</td>
                      <td>22</td>
                      <td>4</td>
                      <td>24,5</td>
                      <td>685</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 350</td>
                      <td>290</td>
                      <td>520</td>
                      <td>460</td>
                      <td>430</td>
                      <td>16</td>
                      <td>22</td>
                      <td>4</td>
                      <td>26,5</td>
                      <td>970</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 400</td>
                      <td>310</td>
                      <td>580</td>
                      <td>515</td>
                      <td>482</td>
                      <td>16</td>
                      <td>26</td>
                      <td>4</td>
                      <td>28</td>
                      <td>1020</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 450</td>
                      <td>330</td>
                      <td>640</td>
                      <td>565</td>
                      <td>532</td>
                      <td>20</td>
                      <td>26</td>
                      <td>4</td>
                      <td>30</td>
                      <td>1120</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 500</td>
                      <td>350</td>
                      <td>715</td>
                      <td>620</td>
                      <td>585</td>
                      <td>20</td>
                      <td>26</td>
                      <td>4</td>
                      <td>31,5</td>
                      <td>1220</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 600</td>
                      <td>390</td>
                      <td>840</td>
                      <td>725</td>
                      <td>685</td>
                      <td>20</td>
                      <td>30</td>
                      <td>5</td>
                      <td>36</td>
                      <td>1370</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры для задвижек 30ч39р PN 1,6 МПа
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>T</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 50</td>
                      <td>150</td>
                      <td>165</td>
                      <td>125</td>
                      <td>102</td>
                      <td>4</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>205</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 65</td>
                      <td>170</td>
                      <td>185</td>
                      <td>145</td>
                      <td>122</td>
                      <td>4</td>
                      <td>18</td>
                      <td>3</td>
                      <td>
                        <p>19</p>
                      </td>
                      <td>228</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 80</td>
                      <td>180</td>
                      <td>200</td>
                      <td>160</td>
                      <td>133</td>
                      <td>4</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>265</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 100</td>
                      <td>190</td>
                      <td>220</td>
                      <td>180</td>
                      <td>158</td>
                      <td>8</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>300</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 125</td>
                      <td>200</td>
                      <td>250</td>
                      <td>210</td>
                      <td>184</td>
                      <td>8</td>
                      <td>18</td>
                      <td>3</td>
                      <td>19</td>
                      <td>355</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 150</td>
                      <td>210</td>
                      <td>285</td>
                      <td>240</td>
                      <td>212</td>
                      <td>8</td>
                      <td>22</td>
                      <td>3</td>
                      <td>19</td>
                      <td>400</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 200</td>
                      <td>230</td>
                      <td>340</td>
                      <td>295</td>
                      <td>268</td>
                      <td>12</td>
                      <td>22</td>
                      <td>3</td>
                      <td>20</td>
                      <td>490</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 250</td>
                      <td>250</td>
                      <td>405</td>
                      <td>355</td>
                      <td>320</td>
                      <td>12</td>
                      <td>26</td>
                      <td>3</td>
                      <td>22</td>
                      <td>585</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 300</td>
                      <td>270</td>
                      <td>460</td>
                      <td>410</td>
                      <td>370</td>
                      <td>12</td>
                      <td>26</td>
                      <td>4</td>
                      <td>24,5</td>
                      <td>685</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 350</td>
                      <td>290</td>
                      <td>520</td>
                      <td>470</td>
                      <td>430</td>
                      <td>16</td>
                      <td>26</td>
                      <td>4</td>
                      <td>26,5</td>
                      <td>970</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 400</td>
                      <td>310</td>
                      <td>580</td>
                      <td>525</td>
                      <td>482</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>28</td>
                      <td>1020</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 450</td>
                      <td>330</td>
                      <td>640</td>
                      <td>585</td>
                      <td>532</td>
                      <td>20</td>
                      <td>30</td>
                      <td>4</td>
                      <td>30</td>
                      <td>1120</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 500</td>
                      <td>350</td>
                      <td>715</td>
                      <td>650</td>
                      <td>585</td>
                      <td>20</td>
                      <td>33</td>
                      <td>4</td>
                      <td>31,5</td>
                      <td>1220</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч39р Ду 600</td>
                      <td>390</td>
                      <td>840</td>
                      <td>770</td>
                      <td>685</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>36</td>
                      <td>1370</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30ч539р с редуктором",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  30ч539р с редуктором
                </h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30ч539р
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30ч539р
                </h3>
                <br />
                <p>Давление PN: 1,0 и 1,6 МПа (10 и 16 кгс/см2)</p>
                <p>
                  Рабочая среда: Вода, пар, неагрессивные жидкие и газообразные
                  среды, по отношению к которым, материалы применяемые в
                  задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от -30 до +130 °С</p>
                <p>Температура окружающего воздуха: от −30°С до +45°С</p>
                <p>Класс герметичности по ГОСТ 9544-2005: "А"</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30ч539р
                </h3>
                <ol>
                  <li>Корпус: Высокопрочный чугун ВЧ50</li>
                  <li>
                    Обрезиненный клин: EPDM (основание — высокопрочный чугун
                    ВЧ50)
                  </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры для задвижек 30ч539р PN 1,0 МПа
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 350</td>
                      <td>290</td>
                      <td>520</td>
                      <td>460</td>
                      <td>430</td>
                      <td>16</td>
                      <td>22</td>
                      <td>4</td>
                      <td>980</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 400</td>
                      <td>310</td>
                      <td>580</td>
                      <td>515</td>
                      <td>482</td>
                      <td>16</td>
                      <td>26</td>
                      <td>4</td>
                      <td>1145</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 450</td>
                      <td>330</td>
                      <td>640</td>
                      <td>565</td>
                      <td>532</td>
                      <td>20</td>
                      <td>26</td>
                      <td>4</td>
                      <td>1245</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 500</td>
                      <td>350</td>
                      <td>715</td>
                      <td>620</td>
                      <td>585</td>
                      <td>20</td>
                      <td>26</td>
                      <td>4</td>
                      <td>1342</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 600</td>
                      <td>390</td>
                      <td>840</td>
                      <td>725</td>
                      <td>685</td>
                      <td>20</td>
                      <td>30</td>
                      <td>5</td>
                      <td>1575</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 700</td>
                      <td>430</td>
                      <td>910</td>
                      <td>840</td>
                      <td>800</td>
                      <td>24</td>
                      <td>30</td>
                      <td>5</td>
                      <td>1390</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 800</td>
                      <td>470</td>
                      <td>1025</td>
                      <td>950</td>
                      <td>905</td>
                      <td>24</td>
                      <td>33</td>
                      <td>5</td>
                      <td>1570</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры для задвижек 30ч539р PN 1,6 МПа
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наменование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 350</td>
                      <td>290</td>
                      <td>520</td>
                      <td>470</td>
                      <td>430</td>
                      <td>16</td>
                      <td>26</td>
                      <td>4</td>
                      <td>980</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 400</td>
                      <td>310</td>
                      <td>580</td>
                      <td>525</td>
                      <td>482</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>1145</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 450</td>
                      <td>330</td>
                      <td>640</td>
                      <td>585</td>
                      <td>532</td>
                      <td>20</td>
                      <td>30</td>
                      <td>4</td>
                      <td>1245</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 500</td>
                      <td>350</td>
                      <td>715</td>
                      <td>650</td>
                      <td>585</td>
                      <td>20</td>
                      <td>33</td>
                      <td>4</td>
                      <td>1342</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 600</td>
                      <td>390</td>
                      <td>840</td>
                      <td>770</td>
                      <td>685</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>1575</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 700</td>
                      <td>430</td>
                      <td>910</td>
                      <td>840</td>
                      <td>800</td>
                      <td>24</td>
                      <td>39</td>
                      <td>5</td>
                      <td>1390</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 800</td>
                      <td>470</td>
                      <td>1025</td>
                      <td>950</td>
                      <td>905</td>
                      <td>24</td>
                      <td>39</td>
                      <td>5</td>
                      <td>1570&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "30ч939р под электропривод",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  30ч939р под электропривод
                </h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек 30ч939р
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек 30ч939р
                </h3>
                <br />
                <p>Давление PN: 1,0 и 1,6 МПа (10 и 16 кгс/см2)</p>
                <p>
                  Рабочая среда: Вода, пар, неагрессивные жидкие и газообразные
                  среды, по отношению к которым, материалы применяемые в
                  задвижке коррозионностойкие
                </p>
                <p>Температура рабочей среды: от -30 до +130 °С</p>
                <p>Температура окружающего воздуха: от −30°С до +45°С</p>
                <p>Класс герметичности по ГОСТ 9544-2005: "А"</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек 30ч939р
                </h3>
                <ol>
                  <li>Корпус: Высокопрочный чугун ВЧ50</li>
                  <li>
                    Обрезиненный клин: EPDM (основание — высокопрочный чугун
                    ВЧ50)
                  </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры для задвижек 30ч539р PN 1,0 МПа
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 350</td>
                      <td>290</td>
                      <td>520</td>
                      <td>460</td>
                      <td>430</td>
                      <td>16</td>
                      <td>22</td>
                      <td>4</td>
                      <td>980</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 400</td>
                      <td>310</td>
                      <td>580</td>
                      <td>515</td>
                      <td>482</td>
                      <td>16</td>
                      <td>26</td>
                      <td>4</td>
                      <td>1145</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 450</td>
                      <td>330</td>
                      <td>640</td>
                      <td>565</td>
                      <td>532</td>
                      <td>20</td>
                      <td>26</td>
                      <td>4</td>
                      <td>1245</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 500</td>
                      <td>350</td>
                      <td>715</td>
                      <td>620</td>
                      <td>585</td>
                      <td>20</td>
                      <td>26</td>
                      <td>4</td>
                      <td>1342</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 600</td>
                      <td>390</td>
                      <td>840</td>
                      <td>725</td>
                      <td>685</td>
                      <td>20</td>
                      <td>30</td>
                      <td>5</td>
                      <td>1575</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 700</td>
                      <td>430</td>
                      <td>910</td>
                      <td>840</td>
                      <td>800</td>
                      <td>24</td>
                      <td>30</td>
                      <td>5</td>
                      <td>1390</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 800</td>
                      <td>470</td>
                      <td>1025</td>
                      <td>950</td>
                      <td>905</td>
                      <td>24</td>
                      <td>33</td>
                      <td>5</td>
                      <td>1570</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры для задвижек 30ч539р PN 1,6 МПа
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>Наменование</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 350</td>
                      <td>290</td>
                      <td>520</td>
                      <td>470</td>
                      <td>430</td>
                      <td>16</td>
                      <td>26</td>
                      <td>4</td>
                      <td>980</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 400</td>
                      <td>310</td>
                      <td>580</td>
                      <td>525</td>
                      <td>482</td>
                      <td>16</td>
                      <td>30</td>
                      <td>4</td>
                      <td>1145</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 450</td>
                      <td>330</td>
                      <td>640</td>
                      <td>585</td>
                      <td>532</td>
                      <td>20</td>
                      <td>30</td>
                      <td>4</td>
                      <td>1245</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 500</td>
                      <td>350</td>
                      <td>715</td>
                      <td>650</td>
                      <td>585</td>
                      <td>20</td>
                      <td>33</td>
                      <td>4</td>
                      <td>1342</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 600</td>
                      <td>390</td>
                      <td>840</td>
                      <td>770</td>
                      <td>685</td>
                      <td>20</td>
                      <td>39</td>
                      <td>5</td>
                      <td>1575</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 700</td>
                      <td>430</td>
                      <td>910</td>
                      <td>840</td>
                      <td>800</td>
                      <td>24</td>
                      <td>39</td>
                      <td>5</td>
                      <td>1390</td>
                    </tr>
                    <tr>
                      <td>Задвижка 30ч539р Ду 800</td>
                      <td>470</td>
                      <td>1025</td>
                      <td>950</td>
                      <td>905</td>
                      <td>24</td>
                      <td>39</td>
                      <td>5</td>
                      <td>1570&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "ЗКС (Задвижки кованые) PN 1,6 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с41нж (31с77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с41нж (31с77нж)</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек кованых ЗКС PN 1,6 МПa
                  31с41нж (31с77нж)
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек кованых ЗКС PN 1,6 МПa 31с41нж
                  (31с77нж)
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек кованых ЗКС PN 1,6 МПa
                  31с41нж (31с77нж)
                </h3>
                <ol>
                  <li>
                    Корпус: сталь 20Задвижки кованные ЗКС 1,6 МПа 31с41ж
                    (31лс77)
                  </li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 1,6 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>DN</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>D0</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>D1</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>L</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>W</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>H (в положении "открыто")</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>Вес, кг</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек кованых ЗКС PN 1,6 МПa 31с41нж
                  (31с77нж) с исполнением: муфтовое резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>DN</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>D0</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>D2</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>L</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>W</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>H (в полжении "открыто")</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <span>
                            <strong>
                              <span>Вес, кг</span>
                            </strong>
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 1,6 МПа с
                  муфтовым присоединением под приварку{" "}
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс41нж (31лс77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс41нж (31лс77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек кованых ЗКС PN 1,6 МПa
                  31лс41нж (31лс77нж)
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения задвижек кованых ЗКС PN 1,6 МПa 31лс41нж
                  (31лс77нж)
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение задвижек кованых ЗКС PN 1,6 МПa
                  31лс41нж (31лс77нж)
                </h3>
                <ol>
                  <li>
                    Корпус: сталь 20ГЗадвижки кованные ЗКС 1,6 МПа 31с41ж
                    (31лс77
                  </li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20Г</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 20Г</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 40Х</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек кованых ЗКС PN 1,6 МПa 31лс41нж
                  (31лс77нж)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>W</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H (в положении "открыто")</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Вес, кг</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры задвижек кованых ЗКС PN 1,6 МПa 31лс41нж
                  (31лс77нж)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>
                            <span>DN</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>W</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>H (в полжении "открыто")</span>
                          </strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>
                            <span>Вес, кг</span>
                          </strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 1,6 МПа с
                  муфтовым присоединением под приварку{" "}
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс41нжТ (31лс77нжТ)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс41нжТ (31лс77нжТ)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 1,6 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +600°С </p>
                <p>Условия эксплуатации: Т1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 1,6 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 15ХМ</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 15ХМ</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 15ХМ</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 1,6 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D1</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в положении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 1,6 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D2</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в полжении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 1,6 МПа с
                  муфтовым присоединением под приварку{" "}
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31нж41нж (31нж77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31нж41нж (31нж77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 1,6 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −196°С до +600°</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 1,6 МПа
                </h3>
                <ol>
                  <li>Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л1. Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 1,6 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D1</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в положении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 1,6 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D0</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>W</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H (в полжении "открыто")</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Вес, кг</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 1,6 МПа с
                  муфтовым присоединением под приварку{" "}
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "ЗКС (Задвижки кованые) PN 2,5 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с64нж (31с77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с64нж (31с77нж)</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 2,5 МПa
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20</li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D1</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в положении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D0</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>W</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H (в полжении "открыто")</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Вес, кг</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 2,5 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс64нж (31лс77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс64нж (31лс77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20Г</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20Г</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 20Г</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 40Х</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <span>DN</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D0</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>L</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>W</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>H (в положении "открыто")</span>
                        </strong>
                      </td>
                      <td>
                        <strong>
                          <span>Вес, кг</span>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D2</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в полжении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 2,5 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс64нжТ (31лс77нжТ)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс64нжТ (31лс77нжТ)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +600°С </p>
                <p>Условия эксплуатации: Т1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 15ХМ</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 15ХМ</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 15ХМ</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>D2</strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>L</strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>W</strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>H (в полжении "открыто")</strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 2,5 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31нж64нж (31нж77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31нж64нж (31нж77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −196°С до +600°</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 2,5 МПа
                </h3>
                <ol>
                  <li>Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D1</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в положении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 2,5 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <td>
                        <span>
                          <strong>DN</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D0</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>D2</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>L</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>W</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>H (в полжении "открыто")</strong>
                        </span>
                      </td>
                      <td>
                        <span>
                          <strong>Вес, кг</strong>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 2,5 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "ЗКС (Задвижки кованые) PN 4,0 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с15нж (31с77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с15нж (31с77нж)</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 4,0 МПa
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20</li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 4,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс15нж (31лс77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс15нж (31лс77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20Г</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20Г</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 20Г</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 40Х</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 4,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс15нжТ (31лс77нжТ)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс15нжТ (31лс77нжТ)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +600°С </p>
                <p>Условия эксплуатации: Т1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 15ХМ</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 15ХМ</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 15ХМ</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 4,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31нж15нж (31нж77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31нж15нж (31нж77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −196°С до +600°</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 4,0 МПа
                </h3>
                <ol>
                  <li>Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 4,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 4,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "ЗКС (Задвижки кованые) PN 6,3 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с76нж (31с77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с76нж (31с77нж)</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 6,3 МПa
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20</li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 6,3 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс76нж (31лс77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс76нж (31лс77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20Г</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20Г</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 20Г</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 40Х</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 6,3 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс76нжТ (31лс77нжТ)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс15нжТ (31лс77нжТ)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С </p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20</li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 6,3 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31нж76нж (31нж77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31нж76нж (31нж77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −196°С до +600°</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 6,3 МПа
                </h3>
                <ol>
                  <li>Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 6,3 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 6,3 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "ЗКС (Задвижки кованые) PN 10 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с77нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с77нж</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 10,0 МПa
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20</li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>DN</th>
                      <th>D0</th>
                      <th>D1</th>
                      <th>L</th>
                      <th>W</th>
                      <th>H (в положении "открыто")</th>
                      <th>Вес, кг</th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 10,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс77нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31лс77нж</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20Г</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20Г</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 20Г</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 40Х</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 10,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс77нжТ",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31лс77нжТ</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +600°С </p>
                <p>Условия эксплуатации: Т1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 15ХМ</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 15ХМ</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 15ХМ</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 10,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31нж77нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31нж77нж</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −196°С до +600°</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 10,0 МПа
                </h3>
                <ol>
                  <li>Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 10,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 10,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "ЗКС (Задвижки кованые) PN 16 МПа",
        subspecies: [
          {
            subspeciesLabel: "31с45нж (31с77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>31с45нж (31с77нж)</h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 16,0 МПa
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20</li>
                  <li>Седло: 20Х13</li>
                  <li>Клин: 20Х13</li>
                  <li>Шток: 20Х13</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 30</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 16,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс45нж (31лс77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс45нж (31лс77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С </p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>Минимальная температура окружающего воздуха: −60°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 20Г</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 20Г</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 20Г</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 40Х</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 16,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31лс45нжТ (31лс77нжТ)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31лс45нжТ (31лс77нжТ)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +600°С </p>
                <p>Условия эксплуатации: Т1</p>
                <p>Минимальная температура окружающего воздуха: −40°С</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <ol>
                  <li>Корпус: сталь 15ХМ</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: сталь 15ХМ</li>
                  <li>Болт: сталь 40Х</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 20Х13</li>
                  <li>Крышка сальника: сталь 15ХМ</li>
                  <li>Болт откидной: сталь 40Х</li>
                  <li>Гайка: сталь 45</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 16,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
          {
            subspeciesLabel: "31нж45нж (31нж77нж)",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>
                  31нж45нж (31нж77нж)
                </h2>
                <h3 className={styles.productInfoText}>
                  Показатели назначения ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, природный газ, жидкие
                  неагрессивные нефтепродукты, неагрессивные жидкие и
                  газообразные среды, по отношению к которым, материалы
                  применяемые в задвижке коррозионностойкие
                </p>

                <p>Температура рабочей среды: от −196°С до +600°</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение ЗКС (компактных задвижек) PN 16,0 МПа
                </h3>
                <ol>
                  <li>Корпус: 08Х18Н10Т</li>
                  <li>Седло: 08Х18Н10Т</li>
                  <li>Клин: 08Х18Н10Т</li>
                  <li>Шток: 08Х18Н10Т</li>
                  <li>Прокладка: ТРГ</li>
                  <li>Крышка: 08Х18Н10Т</li>
                  <li>Болт: 08Х18Н10Т</li>
                  <li>Уплотнение: ТРГ</li>
                  <li>Сальник: 08Х18Н10Т</li>
                  <li>Крышка сальника: 08Х18Н10Т</li>
                  <li>Болт откидной: 08Х18Н10Т</li>
                  <li>Гайка: 08Х18Н10Т</li>
                  <li>Гайка штока: 20Х13</li>
                  <li>Стопорная гайка: сталь 45</li>
                  <li>Маховик: сталь 25Л</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с фланцевым исполнением
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в положении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>10,5</td>
                      <td>12</td>
                      <td>216</td>
                      <td>100</td>
                      <td>163</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>17</td>
                      <td>229</td>
                      <td>100</td>
                      <td>163</td>
                      <td>6,4</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>22</td>
                      <td>254</td>
                      <td>125</td>
                      <td>201</td>
                      <td>9,2</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>28</td>
                      <td>280</td>
                      <td>160</td>
                      <td>221</td>
                      <td>12,4</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>35</td>
                      <td>240</td>
                      <td>160</td>
                      <td>248</td>
                      <td>15</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>42</td>
                      <td>292</td>
                      <td>200</td>
                      <td>283</td>
                      <td>23,2</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры ЗКС PN 16,0 МПа с исполнением: муфтовое
                  резьбовое
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>DN</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>W</span>
                      </th>
                      <th>
                        <span>H (в полжении "открыто")</span>
                      </th>
                      <th>
                        <span>Вес, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>13,5</td>
                      <td>Rc 1/2</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,225</td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>13,5</td>
                      <td>Rc 3/4</td>
                      <td>90</td>
                      <td>100</td>
                      <td>163</td>
                      <td>2,3</td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>18</td>
                      <td>Rc 1</td>
                      <td>105</td>
                      <td>125</td>
                      <td>201</td>
                      <td>3,65</td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>22</td>
                      <td>Rc 5/4</td>
                      <td>124</td>
                      <td>160</td>
                      <td>221</td>
                      <td>5,05</td>
                    </tr>
                    <tr>
                      <td>40</td>
                      <td>29</td>
                      <td>Rc 3/2</td>
                      <td>124</td>
                      <td>160</td>
                      <td>248</td>
                      <td>5,35</td>
                    </tr>
                    <tr>
                      <td>50</td>
                      <td>36</td>
                      <td>Rc 2</td>
                      <td>130</td>
                      <td>200</td>
                      <td>283</td>
                      <td>8,1</td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  *Возможно изготовление задвижек кованых (ЗКС) PN 16,0 МПа с
                  муфтовым присоединением под приварку
                </p>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан запорный PN 1,6 МПа",
        subspecies: [
          {
            subspeciesLabel: "15с65нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15с65нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15с65нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15с65нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение В
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15с65нж
                </h3>
                <ol>
                  <li>Корпус: 25Л</li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>
                    Диск: 25Л+сталь по типу 20Х13Клапан (вентиль) запорный
                    стальной фланцевый 15с65нж
                  </li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 25</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Крышка: 25Л</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: Сталь 25</li>
                  <li>Крышка сальника: 25Л</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15с65нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;102</td>
                      <td>&nbsp;320</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18,4</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;352</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;390</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>215</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;427</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;320</td>
                      <td>245</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;473</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>280</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;570</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>84</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;550</td>
                      <td>335</td>
                      <td>&nbsp;295</td>
                      <td>&nbsp;268</td>
                      <td>&nbsp;653</td>
                      <td>3</td>
                      <td>12</td>
                      <td>22</td>
                      <td>145</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с65нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>405</td>
                      <td>&nbsp;355</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;750</td>
                      <td>3</td>
                      <td>12</td>
                      <td>26</td>
                      <td>232</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15лс65нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15лс65нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15лс65нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15лс65нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение В
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15лс65нж
                </h3>
                <ol>
                  <li>
                    Корпус: 20ГЛКлапан (вентиль) запорный стальной фланцевый
                    15лс65нж
                  </li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 20ГЛ+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 35ХМ</li>
                  <li>Гайка: Сталь 40Х</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: 35ХМ</li>
                  <li>Крышка сальника: 20ГЛ</li>
                  <li>Гайка: 40Х</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15лс65нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D0</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>h</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>n</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>d</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;102</td>
                      <td>&nbsp;320</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18,4</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;352</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;390</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>215</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;427</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;320</td>
                      <td>245</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;473</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>280</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;570</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>84</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;550</td>
                      <td>335</td>
                      <td>&nbsp;295</td>
                      <td>&nbsp;268</td>
                      <td>&nbsp;653</td>
                      <td>3</td>
                      <td>12</td>
                      <td>22</td>
                      <td>145</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс65нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>405</td>
                      <td>&nbsp;355</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;750</td>
                      <td>3</td>
                      <td>12</td>
                      <td>26</td>
                      <td>232</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15нж65нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15нж65нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15нж65нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15нж65нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение В
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15нж65нж
                </h3>
                <ol>
                  <li>
                    Корпус: 12Х18Н9ТЛКлапан (вентиль) запорный стальной
                    фланцевый 15нж65нж
                  </li>
                  <li>Наплавка седла: Стеллит</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Шпиндель: 12Х18Н10Т</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 08Х18Н10</li>
                  <li>Втулка нажимная: 08Х18Н10</li>
                  <li>Откидной болт: 08Х18Н10</li>
                  <li>Крышка сальника: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15нж65нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;102</td>
                      <td>&nbsp;320</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18,4</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;352</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;390</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>215</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;427</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>43</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;320</td>
                      <td>245</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;473</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>65</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>280</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;570</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>84</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;550</td>
                      <td>335</td>
                      <td>&nbsp;295</td>
                      <td>&nbsp;268</td>
                      <td>&nbsp;653</td>
                      <td>3</td>
                      <td>12</td>
                      <td>22</td>
                      <td>145</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж65нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>405</td>
                      <td>&nbsp;355</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;750</td>
                      <td>3</td>
                      <td>12</td>
                      <td>26</td>
                      <td>232</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан запорный PN 2,5 МПа",
        subspecies: [
          {
            subspeciesLabel: "15с18нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15с18нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15с18нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15с18нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение В
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15с18нж
                </h3>
                <ol>
                  <li>
                    Корпус: 25ЛКлапан (вентиль) запорный стальной фланцевый
                    15с18нж
                  </li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 25Л+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 25</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Крышка: 25Л</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: Сталь 25</li>
                  <li>Крышка сальника: 25Л</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15с65нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;102</td>
                      <td>&nbsp;320</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;280</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;352</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;390</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;427</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;380</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;473</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>67</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;570</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>90</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;550</td>
                      <td>360</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;278</td>
                      <td>&nbsp;653</td>
                      <td>3</td>
                      <td>12</td>
                      <td>26</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с18нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>425</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;750</td>
                      <td>3</td>
                      <td>12</td>
                      <td>30</td>
                      <td>245</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15лс18нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15лс18нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15лс18нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15лс18нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение В
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15лс18нж
                </h3>
                <ol>
                  <li>
                    Корпус: 20ГЛКлапан (вентиль) запорный стальной фланцевый
                    15лс18нж
                  </li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 20ГЛ+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 35ХМ</li>
                  <li>Гайка: Сталь 40Х</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: 35ХМ</li>
                  <li>Крышка сальника: 20ГЛ</li>
                  <li>Гайка: 40Х</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15лс18нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;102</td>
                      <td>&nbsp;320</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;352</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;390</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;427</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;320</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;473</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>67</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;570</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>90</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;550</td>
                      <td>360</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;278</td>
                      <td>&nbsp;653</td>
                      <td>3</td>
                      <td>12</td>
                      <td>26</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс18нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>425</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;750</td>
                      <td>3</td>
                      <td>12</td>
                      <td>30</td>
                      <td>245</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15нж18нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15нж18нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15нж18нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15нж18нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение В
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15нж18нж
                </h3>
                <ol>
                  <li>
                    Корпус: 12Х18Н9ТЛКлапан (вентиль) запорный стальной
                    фланцевый 15нж18нж
                  </li>
                  <li>Наплавка седла: Стеллит</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Шпиндель: 12Х18Н10Т</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 08Х18Н10</li>
                  <li>Втулка нажимная: 08Х18Н10</li>
                  <li>Откидной болт: 08Х18Н10</li>
                  <li>Крышка сальника: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15нж18нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;102</td>
                      <td>&nbsp;320</td>
                      <td>3</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;352</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;390</td>
                      <td>3</td>
                      <td>8</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;427</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>45</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;320</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;473</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>67</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;570</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>90</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;550</td>
                      <td>360</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;278</td>
                      <td>&nbsp;653</td>
                      <td>3</td>
                      <td>12</td>
                      <td>26</td>
                      <td>160</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15нж18нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>425</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;750</td>
                      <td>3</td>
                      <td>12</td>
                      <td>30</td>
                      <td>245</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан запорный PN 4,0 МПа",
        subspecies: [
          {
            subspeciesLabel: "15с22нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15с22нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15с22нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15с22нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение Е
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15с22нж
                </h3>
                <ol>
                  <li>
                    Корпус: 25ЛКлапан (вентиль) запорный стальной фланцевый
                    15с22нж
                  </li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 25Л+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 25</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Крышка: 25Л</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: Сталь 25</li>
                  <li>Крышка сальника: 25Л</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15с22нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;87</td>
                      <td>&nbsp;320</td>
                      <td>4</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;280</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;109</td>
                      <td>&nbsp;352</td>
                      <td>4</td>
                      <td>8</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;120</td>
                      <td>&nbsp;390</td>
                      <td>4</td>
                      <td>8</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;149</td>
                      <td>&nbsp;427</td>
                      <td>4</td>
                      <td>8</td>
                      <td>22</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;380</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;175</td>
                      <td>&nbsp;473</td>
                      <td>4</td>
                      <td>8</td>
                      <td>26</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;203</td>
                      <td>&nbsp;570</td>
                      <td>4</td>
                      <td>8</td>
                      <td>26</td>
                      <td>103</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;500</td>
                      <td>375</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;259</td>
                      <td>&nbsp;653</td>
                      <td>4</td>
                      <td>12</td>
                      <td>30</td>
                      <td>192</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с22нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>445</td>
                      <td>&nbsp;385</td>
                      <td>&nbsp;312</td>
                      <td>&nbsp;750</td>
                      <td>4</td>
                      <td>12</td>
                      <td>33</td>
                      <td>292</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15лс22нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15лс22нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15лс22нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15лс22нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение Е
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15лс22нж
                </h3>
                <ol>
                  <li>
                    Корпус: 20ГЛКлапан (вентиль) запорный стальной фланцевый
                    15лс22нж
                  </li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 20ГЛ+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 35ХМ</li>
                  <li>Гайка: Сталь 40Х</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: 35ХМ</li>
                  <li>Крышка сальника: 20ГЛ</li>
                  <li>Гайка: 40Х</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15лс22нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D4</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;87</td>
                      <td>&nbsp;320</td>
                      <td>4</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;109</td>
                      <td>&nbsp;352</td>
                      <td>4</td>
                      <td>8</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;120</td>
                      <td>&nbsp;390</td>
                      <td>4</td>
                      <td>8</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;149</td>
                      <td>&nbsp;427</td>
                      <td>4</td>
                      <td>8</td>
                      <td>22</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <td>
                        <p>Клапан (вентиль) 15лс22нж Ду 125</p>
                      </td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;380</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;175</td>
                      <td>&nbsp;473</td>
                      <td>4</td>
                      <td>8</td>
                      <td>26</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;203</td>
                      <td>&nbsp;570</td>
                      <td>4</td>
                      <td>8</td>
                      <td>26</td>
                      <td>103</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;500</td>
                      <td>375</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;259</td>
                      <td>&nbsp;653</td>
                      <td>4</td>
                      <td>12</td>
                      <td>30</td>
                      <td>192</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15лс22нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>445</td>
                      <td>&nbsp;385</td>
                      <td>&nbsp;312</td>
                      <td>&nbsp;750</td>
                      <td>4</td>
                      <td>12</td>
                      <td>33</td>
                      <td>292</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15нж22нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15нж22нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15нж22нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15нж22нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015:
                  исполнение Е
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15нж22нж
                </h3>
                <ol>
                  <li>
                    Корпус: 12Х18Н9ТЛКлапан (вентиль) запорный стальной
                    фланцевый 15нж22нж
                  </li>
                  <li>Наплавка седла: Стеллит</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Шпиндель: 12Х18Н10Т</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 08Х18Н10</li>
                  <li>Втулка нажимная: 08Х18Н10</li>
                  <li>Откидной болт: 08Х18Н10</li>
                  <li>Крышка сальника: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15нж22нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль)&nbsp;15нж22нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;220</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;87</td>
                      <td>&nbsp;320</td>
                      <td>4</td>
                      <td>4</td>
                      <td>18</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;250</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;109</td>
                      <td>&nbsp;352</td>
                      <td>4</td>
                      <td>8</td>
                      <td>18</td>
                      <td>28</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;280</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;120</td>
                      <td>&nbsp;390</td>
                      <td>4</td>
                      <td>8</td>
                      <td>18</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;320</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;149</td>
                      <td>&nbsp;427</td>
                      <td>4</td>
                      <td>8</td>
                      <td>22</td>
                      <td>48</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;380</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;175</td>
                      <td>&nbsp;473</td>
                      <td>4</td>
                      <td>8</td>
                      <td>26</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;450</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;203</td>
                      <td>&nbsp;570</td>
                      <td>4</td>
                      <td>8</td>
                      <td>26</td>
                      <td>103</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 200</td>
                      <td>&nbsp;600</td>
                      <td>&nbsp;500</td>
                      <td>375</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;259</td>
                      <td>&nbsp;653</td>
                      <td>4</td>
                      <td>12</td>
                      <td>30</td>
                      <td>192</td>
                    </tr>
                    <tr>
                      <td>Клапан (Вентиль) 15нж22нж Ду 250</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;600</td>
                      <td>445</td>
                      <td>&nbsp;385</td>
                      <td>&nbsp;312</td>
                      <td>&nbsp;750</td>
                      <td>4</td>
                      <td>12</td>
                      <td>33</td>
                      <td>292</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан запорный PN 6,3 МПа",
        subspecies: [
          {
            subspeciesLabel: "15с27нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15с27нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15с27нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15с27нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −40°С до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015: DN 50
                  - 200мм. — исполнение J
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15с27нж
                </h3>
                <ol>
                  <li>Корпус: 25Л</li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 25Л+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 25</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Крышка: 25Л</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: Сталь 25</li>
                  <li>Крышка сальника: 25Л</li>
                  <li>Гайка: Сталь 35</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15с27нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D0</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D9</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D8</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>h</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>n</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>d</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 50</td>
                      <td>&nbsp;300</td>
                      <td>&nbsp;280</td>
                      <td>175</td>
                      <td>&nbsp;135</td>
                      <td>&nbsp;102</td>
                      <td>85</td>
                      <td>&nbsp;370</td>
                      <td>3</td>
                      <td>4</td>
                      <td>22</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 65</td>
                      <td>&nbsp;340</td>
                      <td>&nbsp;320</td>
                      <td>200</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;132</td>
                      <td>110</td>
                      <td>&nbsp;415</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>47</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 80</td>
                      <td>&nbsp;380</td>
                      <td>&nbsp;320</td>
                      <td>210</td>
                      <td>&nbsp;170</td>
                      <td>&nbsp;133</td>
                      <td>150</td>
                      <td>&nbsp;460</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 100</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;450</td>
                      <td>250</td>
                      <td>&nbsp;200</td>
                      <td>&nbsp;170</td>
                      <td>145</td>
                      <td>&nbsp;555</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>110</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 125</td>
                      <td>&nbsp;500</td>
                      <td>&nbsp;450</td>
                      <td>295</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;205</td>
                      <td>175</td>
                      <td>&nbsp;638</td>
                      <td>3</td>
                      <td>8</td>
                      <td>30</td>
                      <td>178</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 150</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;500</td>
                      <td>340</td>
                      <td>&nbsp;280</td>
                      <td>&nbsp;240</td>
                      <td>205</td>
                      <td>&nbsp;720</td>
                      <td>3</td>
                      <td>8</td>
                      <td>33</td>
                      <td>240</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль) 15с27нж Ду 200</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;600</td>
                      <td>405</td>
                      <td>&nbsp;345</td>
                      <td>&nbsp;285</td>
                      <td>265</td>
                      <td>&nbsp;875</td>
                      <td>3</td>
                      <td>12</td>
                      <td>33</td>
                      <td>397</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15лс27нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15лс27нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15лс27нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15лс27нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015: DN 50
                  - 200мм. — исполнение J
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15лс27нж
                </h3>
                <ol>
                  <li>Корпус: 20ГЛ</li>
                  <li>Наплавка седла: сталь по типу 20Х13</li>
                  <li>Диск: 20ГЛ+сталь по типу 20Х13</li>
                  <li>Шпиндель: 20Х13</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: Сталь 35ХМ</li>
                  <li>Гайка: Сталь 40Х</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 20Х13</li>
                  <li>Втулка нажимная: 20Х13</li>
                  <li>Откидной болт: 35ХМ</li>
                  <li>Крышка сальника: 20ГЛ</li>
                  <li>Гайка: 40Х</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15лс27нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>D9</span>
                      </th>
                      <th>
                        <span>D8</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>h</span>
                      </th>
                      <th>
                        <span>n</span>
                      </th>
                      <th>
                        <span>d</span>
                      </th>
                      <th>
                        <span>Масса, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 50</td>
                      <td>&nbsp;300</td>
                      <td>&nbsp;280</td>
                      <td>175</td>
                      <td>&nbsp;135</td>
                      <td>&nbsp;102</td>
                      <td>85</td>
                      <td>&nbsp;370</td>
                      <td>3</td>
                      <td>4</td>
                      <td>22</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 65</td>
                      <td>&nbsp;340</td>
                      <td>&nbsp;320</td>
                      <td>200</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;132</td>
                      <td>110</td>
                      <td>&nbsp;415</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>47</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 80</td>
                      <td>&nbsp;380</td>
                      <td>&nbsp;320</td>
                      <td>210</td>
                      <td>&nbsp;170</td>
                      <td>&nbsp;133</td>
                      <td>115</td>
                      <td>&nbsp;460</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 100</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;450</td>
                      <td>250</td>
                      <td>&nbsp;200</td>
                      <td>&nbsp;170</td>
                      <td>145</td>
                      <td>&nbsp;555</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>110</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 125</td>
                      <td>&nbsp;500</td>
                      <td>&nbsp;450</td>
                      <td>295</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;205</td>
                      <td>175</td>
                      <td>&nbsp;638</td>
                      <td>3</td>
                      <td>8</td>
                      <td>30</td>
                      <td>178</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 150</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;500</td>
                      <td>340</td>
                      <td>&nbsp;280</td>
                      <td>&nbsp;240</td>
                      <td>205</td>
                      <td>&nbsp;720</td>
                      <td>3</td>
                      <td>8</td>
                      <td>33</td>
                      <td>240</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15лс27нж Ду 200</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;600</td>
                      <td>405</td>
                      <td>&nbsp;345</td>
                      <td>&nbsp;285</td>
                      <td>265</td>
                      <td>&nbsp;875</td>
                      <td>3</td>
                      <td>12</td>
                      <td>33</td>
                      <td>397</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "15нж27нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>15нж27нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики задвижек стальных клиновых
                  фланцевых 15нж27нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов запорных стальных 15нж27нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от −60°С до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <p>
                  Направление подачи рабочей среды: одностороннее по маркировке
                  на корпусе
                </p>
                <p>
                  Установочное положение клапана: управляющим органом вверх.
                  Допускается отклонение от вертикали до 90° в любую сторону
                </p>
                <p>
                  Фланцевые присоединительные размеры по ГОСТ 33259-2015: DN 50
                  - 200мм. — исполнение J
                </p>
                <p>Присоединение к трубопроводу: под приварку, фланцевое</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов запорных стальных 15нж27нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ</li>
                  <li>Наплавка седла: Стеллит</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Шпиндель: 12Х18Н10Т</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Набивка сальника: ТРГ</li>
                  <li>Штифт: 08Х18Н10</li>
                  <li>Втулка нажимная: 08Х18Н10</li>
                  <li>Откидной болт: 08Х18Н10</li>
                  <li>Крышка сальника: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Гайка шпинделя: Латунь ЛС59-1</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                  <li>Маховик: 25Л</li>
                  <li>Контргайка: Сталь 45</li>
                  <li>Редуктор: —</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов запорных стальных 15нж27нж
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D0</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>D9</span>
                      </th>
                      <th>
                        <span>D8</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>h</span>
                      </th>
                      <th>
                        <span>n</span>
                      </th>
                      <th>
                        <span>d</span>
                      </th>
                      <th>
                        <span>Масса, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 50</td>
                      <td>&nbsp;300</td>
                      <td>&nbsp;280</td>
                      <td>175</td>
                      <td>&nbsp;135</td>
                      <td>&nbsp;102</td>
                      <td>85</td>
                      <td>&nbsp;370</td>
                      <td>3</td>
                      <td>4</td>
                      <td>22</td>
                      <td>35</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 65</td>
                      <td>&nbsp;340</td>
                      <td>&nbsp;320</td>
                      <td>200</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;132</td>
                      <td>110</td>
                      <td>&nbsp;415</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>47</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 80</td>
                      <td>&nbsp;380</td>
                      <td>&nbsp;320</td>
                      <td>210</td>
                      <td>&nbsp;170</td>
                      <td>&nbsp;133</td>
                      <td>115</td>
                      <td>&nbsp;460</td>
                      <td>3</td>
                      <td>8</td>
                      <td>22</td>
                      <td>60</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 100</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;450</td>
                      <td>250</td>
                      <td>&nbsp;200</td>
                      <td>&nbsp;170</td>
                      <td>145</td>
                      <td>&nbsp;555</td>
                      <td>3</td>
                      <td>8</td>
                      <td>26</td>
                      <td>110</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 125</td>
                      <td>&nbsp;500</td>
                      <td>&nbsp;450</td>
                      <td>295</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;205</td>
                      <td>175</td>
                      <td>&nbsp;638</td>
                      <td>3</td>
                      <td>8</td>
                      <td>30</td>
                      <td>178</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 150</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;500</td>
                      <td>340</td>
                      <td>&nbsp;280</td>
                      <td>&nbsp;240</td>
                      <td>205</td>
                      <td>&nbsp;720</td>
                      <td>3</td>
                      <td>8</td>
                      <td>33</td>
                      <td>240</td>
                    </tr>
                    <tr>
                      <td>Клапан (вентиль)&nbsp;15нж27нж Ду 200</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;600</td>
                      <td>405</td>
                      <td>&nbsp;345</td>
                      <td>&nbsp;285</td>
                      <td>265</td>
                      <td>&nbsp;875</td>
                      <td>3</td>
                      <td>12</td>
                      <td>33</td>
                      <td>397</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан обратный поворотный (затвор обратный) PN 1,6 МПа",
        subspecies: [
          {
            subspeciesLabel: "19с76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19с76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19с76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19с76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -40 до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19с76нж
                </h3>
                <ol>
                  <li>Корпус: углеродистая сталь 25Л</li>
                  <li>Седло: 25Л+20Х13</li>
                  <li>Диск: 25Л+20Х13</li>
                  <li>Скоба: 25Л</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 20Х13</li>
                  <li>Кронштейн: 25Л</li>
                  <li>Гайка: сталь 45</li>
                  <li>Шпилька: сталь 35</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 25Л</li>
                  <li>Рым-болт: сталь 45</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных 19с76нж
                  (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 50</td>
                      <td>230</td>
                      <td>160</td>
                      <td>125</td>
                      <td>102</td>
                      <td>135</td>
                      <td>&nbsp;14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;142</td>
                      <td>&nbsp;18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;165</td>
                      <td>&nbsp;23</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;215</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;32</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;245</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;55</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;280</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;233</td>
                      <td>&nbsp;70</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 200</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;295</td>
                      <td>&nbsp;268</td>
                      <td>&nbsp;304</td>
                      <td>&nbsp;108</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 250</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;405</td>
                      <td>&nbsp;355</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;348</td>
                      <td>&nbsp;175</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 300</td>
                      <td>&nbsp;750</td>
                      <td>&nbsp;460</td>
                      <td>&nbsp;410</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;390</td>
                      <td>&nbsp;280</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 350</td>
                      <td>&nbsp;850</td>
                      <td>&nbsp;520</td>
                      <td>&nbsp;470</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;420</td>
                      <td>&nbsp;395</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 400</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;580</td>
                      <td>&nbsp;525</td>
                      <td>&nbsp;482</td>
                      <td>&nbsp;468</td>
                      <td>&nbsp;485</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 500</td>
                      <td>&nbsp;1150</td>
                      <td>&nbsp;710</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;585</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;850</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 600</td>
                      <td>&nbsp;1350</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;770</td>
                      <td>&nbsp;685</td>
                      <td>&nbsp;620</td>
                      <td>&nbsp;1190</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 700</td>
                      <td>&nbsp;1450</td>
                      <td>&nbsp;910</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;800</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;1650</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 800</td>
                      <td>&nbsp;1650</td>
                      <td>&nbsp;1020</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;905</td>
                      <td>&nbsp;720</td>
                      <td>&nbsp;2250</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 1000</td>
                      <td>&nbsp;2050</td>
                      <td>&nbsp;1255</td>
                      <td>&nbsp;1170</td>
                      <td>&nbsp;1110</td>
                      <td>&nbsp;890</td>
                      <td>&nbsp;3685</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19лс76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19лс76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19лс76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19лс76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19лс76нж
                </h3>
                <ol>
                  <li>Корпус: Сталь 20ГЛ</li>
                  <li>Седло: 20ГЛ+08Х18Н10</li>
                  <li>Диск: 20ГЛ+08Х18Н10</li>
                  <li>Скоба: 20ГЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: 20ГЛ</li>
                  <li>Гайка: 25ХМ</li>
                  <li>Шпилька: 42ХМ</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Рым-болт: 42ХМ</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных 19лс76нж
                  (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>
                          <strong>
                            <span>Наименование</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>L</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D1</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>D2</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>H</span>
                          </strong>
                        </span>
                      </th>
                      <th>
                        <span>
                          <strong>
                            <span>Масса, кг</span>
                          </strong>
                        </span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 50</td>
                      <td>230</td>
                      <td>160</td>
                      <td>125</td>
                      <td>102</td>
                      <td>135</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;142</td>
                      <td>&nbsp;18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;165</td>
                      <td>&nbsp;23</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;215</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;32</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;245</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;55</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;280</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;233</td>
                      <td>&nbsp;70</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 200</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;295</td>
                      <td>&nbsp;268</td>
                      <td>&nbsp;304</td>
                      <td>&nbsp;108</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 250</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;405</td>
                      <td>&nbsp;355</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;348</td>
                      <td>&nbsp;175</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 300</td>
                      <td>&nbsp;750</td>
                      <td>&nbsp;460</td>
                      <td>&nbsp;410</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;390</td>
                      <td>&nbsp;280</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 350</td>
                      <td>&nbsp;850</td>
                      <td>&nbsp;520</td>
                      <td>&nbsp;470</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;420</td>
                      <td>&nbsp;395</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 400</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;580</td>
                      <td>&nbsp;525</td>
                      <td>&nbsp;482</td>
                      <td>&nbsp;468</td>
                      <td>&nbsp;485</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 500</td>
                      <td>&nbsp;1150</td>
                      <td>&nbsp;710</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;585</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;850</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 600</td>
                      <td>&nbsp;1350</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;770</td>
                      <td>&nbsp;685</td>
                      <td>&nbsp;620</td>
                      <td>&nbsp;1190</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 700</td>
                      <td>&nbsp;1450</td>
                      <td>&nbsp;910</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;800</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;1650</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 800</td>
                      <td>&nbsp;1650</td>
                      <td>&nbsp;1020</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;905</td>
                      <td>&nbsp;720</td>
                      <td>&nbsp;2250</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 1000</td>
                      <td>&nbsp;2050</td>
                      <td>&nbsp;1255</td>
                      <td>&nbsp;1170</td>
                      <td>&nbsp;1110</td>
                      <td>&nbsp;890</td>
                      <td>&nbsp;3685</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19нж76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19нж76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19нж76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19нж76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19нж76нж
                </h3>
                <ol>
                  <li>Корпус: Сталь 12Х18Н9ТЛ</li>
                  <li>Седло: 12Х18Н9ТЛ</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Скоба: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: сталь 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Рым-болт: 08Х18Н10</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных 19нж76нж
                  (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 50</td>
                      <td>230</td>
                      <td>160</td>
                      <td>125</td>
                      <td>102</td>
                      <td>135</td>
                      <td>&nbsp;14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;142</td>
                      <td>&nbsp;18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;165</td>
                      <td>&nbsp;23</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;215</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;32</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;245</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;210</td>
                      <td>&nbsp;55</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;280</td>
                      <td>&nbsp;240</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;233</td>
                      <td>&nbsp;70</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 200</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;295</td>
                      <td>&nbsp;268</td>
                      <td>&nbsp;304</td>
                      <td>&nbsp;108</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 250</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;405</td>
                      <td>&nbsp;355</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;348</td>
                      <td>&nbsp;175</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 300</td>
                      <td>&nbsp;750</td>
                      <td>&nbsp;460</td>
                      <td>&nbsp;410</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;390</td>
                      <td>&nbsp;280</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 350</td>
                      <td>&nbsp;850</td>
                      <td>&nbsp;520</td>
                      <td>&nbsp;470</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;420</td>
                      <td>&nbsp;395</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 400</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;580</td>
                      <td>&nbsp;525</td>
                      <td>&nbsp;482</td>
                      <td>&nbsp;468</td>
                      <td>&nbsp;485</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 500</td>
                      <td>&nbsp;1150</td>
                      <td>&nbsp;710</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;585</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;850</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 600</td>
                      <td>&nbsp;1350</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;770</td>
                      <td>&nbsp;685</td>
                      <td>&nbsp;620</td>
                      <td>&nbsp;1190</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 700</td>
                      <td>&nbsp;1450</td>
                      <td>&nbsp;910</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;800</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;1650</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 800</td>
                      <td>&nbsp;1650</td>
                      <td>&nbsp;1020</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;905</td>
                      <td>&nbsp;720</td>
                      <td>&nbsp;2250</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 1000</td>
                      <td>&nbsp;2050</td>
                      <td>&nbsp;1255</td>
                      <td>&nbsp;1170</td>
                      <td>&nbsp;1110</td>
                      <td>&nbsp;890</td>
                      <td>&nbsp;3685</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан обратный поворотный (затвор обратный) PN 2,5 МПа",
        subspecies: [
          {
            subspeciesLabel: "19с76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19с76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19с76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19с76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -40 до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19с76нж
                </h3>
                <ol>
                  <li>Корпус: углеродистая сталь 25Л </li>
                  <li>Седло: 25Л+20Х13</li>
                  <li>Диск: 25Л+20Х13</li>
                  <li>Скоба: 25Л</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 20Х13</li>
                  <li>Кронштейн: 25Л</li>
                  <li>Гайка: сталь 45</li>
                  <li>Шпилька: сталь 35</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 25Л</li>
                  <li>Рым-болт: сталь 45</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19с76нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>160</td>
                      <td>125</td>
                      <td>102</td>
                      <td>135</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;142</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;165</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;180</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;210</td>
                      <td>58</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;233</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 200</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;360</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;278</td>
                      <td>&nbsp;304</td>
                      <td>115</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 250</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;425</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;348</td>
                      <td>205</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 300</td>
                      <td>&nbsp;750</td>
                      <td>&nbsp;485</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;390</td>
                      <td>&nbsp;390</td>
                      <td>310</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 350</td>
                      <td>&nbsp;850</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;490</td>
                      <td>&nbsp;450</td>
                      <td>&nbsp;420</td>
                      <td>420</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 400</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;610</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;505</td>
                      <td>&nbsp;468</td>
                      <td>540</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 500</td>
                      <td>&nbsp;1150</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;660</td>
                      <td>&nbsp;615</td>
                      <td>&nbsp;550</td>
                      <td>905</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 600</td>
                      <td>&nbsp;1350</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;770</td>
                      <td>&nbsp;720</td>
                      <td>&nbsp;620</td>
                      <td>1320</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 700</td>
                      <td>&nbsp;1450</td>
                      <td>&nbsp;960</td>
                      <td>&nbsp;875</td>
                      <td>&nbsp;820</td>
                      <td>&nbsp;650</td>
                      <td>1800</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 800</td>
                      <td>&nbsp;1650</td>
                      <td>&nbsp;1075</td>
                      <td>&nbsp;990</td>
                      <td>&nbsp;930</td>
                      <td>&nbsp;720</td>
                      <td>2450</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с76нж Ду 1000</td>
                      <td>&nbsp;2050</td>
                      <td>&nbsp;1315</td>
                      <td>&nbsp;1210</td>
                      <td>&nbsp;1140</td>
                      <td>&nbsp;890</td>
                      <td>3860</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19лс76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19лс76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19лс76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19лс76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19лс76нж
                </h3>
                <ol>
                  <li>Корпус: Сталь 20ГЛ</li>
                  <li>Седло: 20ГЛ+08Х18Н10</li>
                  <li>Диск: 20ГЛ+08Х18Н10</li>
                  <li>Скоба: 20ГЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: 20ГЛ</li>
                  <li>Гайка: 25ХМ</li>
                  <li>Шпилька: 42ХМ</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Рым-болт: 42ХМ</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19лс76нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <span>Наименование</span>
                      </th>
                      <th>
                        <span>L</span>
                      </th>
                      <th>
                        <span>D</span>
                      </th>
                      <th>
                        <span>D1</span>
                      </th>
                      <th>
                        <span>D2</span>
                      </th>
                      <th>
                        <span>H</span>
                      </th>
                      <th>
                        <span>Масса, кг</span>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>160</td>
                      <td>125</td>
                      <td>102</td>
                      <td>135</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;142</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;165</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;180</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;210</td>
                      <td>58</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;233</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 200</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;360</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;278</td>
                      <td>&nbsp;304</td>
                      <td>115</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 250</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;425</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;348</td>
                      <td>205</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 300</td>
                      <td>&nbsp;750</td>
                      <td>&nbsp;485</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;390</td>
                      <td>&nbsp;390</td>
                      <td>310</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 350</td>
                      <td>&nbsp;850</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;490</td>
                      <td>&nbsp;450</td>
                      <td>&nbsp;420</td>
                      <td>420</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 400</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;610</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;505</td>
                      <td>&nbsp;468</td>
                      <td>540</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 500</td>
                      <td>&nbsp;1150</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;660</td>
                      <td>&nbsp;615</td>
                      <td>&nbsp;550</td>
                      <td>905</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 600</td>
                      <td>&nbsp;1350</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;770</td>
                      <td>&nbsp;720</td>
                      <td>&nbsp;620</td>
                      <td>1320</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 700</td>
                      <td>&nbsp;1450</td>
                      <td>&nbsp;960</td>
                      <td>&nbsp;875</td>
                      <td>&nbsp;820</td>
                      <td>&nbsp;650</td>
                      <td>1800</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 800</td>
                      <td>&nbsp;1650</td>
                      <td>&nbsp;1075</td>
                      <td>&nbsp;990</td>
                      <td>&nbsp;930</td>
                      <td>&nbsp;720</td>
                      <td>2450</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс76нж Ду 1000</td>
                      <td>&nbsp;2050</td>
                      <td>&nbsp;1315</td>
                      <td>&nbsp;1210</td>
                      <td>&nbsp;1140</td>
                      <td>&nbsp;890</td>
                      <td>3860</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19нж76нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19нж76нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19нж76нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19нж76нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19нж76нж
                </h3>
                <ol>
                  <li>Корпус: Сталь 12Х18Н9ТЛ</li>
                  <li>Седло: 12Х18Н9ТЛ</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Скоба: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: сталь 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Шпилька 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Рым-болт: 08Х18Н10</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19нж76нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 50</td>
                      <td>&nbsp;230</td>
                      <td>160</td>
                      <td>125</td>
                      <td>102</td>
                      <td>135</td>
                      <td>14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 65</td>
                      <td>&nbsp;290</td>
                      <td>&nbsp;180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;122</td>
                      <td>&nbsp;142</td>
                      <td>18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 80</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;133</td>
                      <td>&nbsp;165</td>
                      <td>23</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 100</td>
                      <td>&nbsp;350</td>
                      <td>&nbsp;230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;158</td>
                      <td>&nbsp;180</td>
                      <td>34</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 125</td>
                      <td>&nbsp;400</td>
                      <td>&nbsp;270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;184</td>
                      <td>&nbsp;210</td>
                      <td>58</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 150</td>
                      <td>&nbsp;480</td>
                      <td>&nbsp;300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;212</td>
                      <td>&nbsp;233</td>
                      <td>75</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 200</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;360</td>
                      <td>&nbsp;310</td>
                      <td>&nbsp;278</td>
                      <td>&nbsp;304</td>
                      <td>115</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 250</td>
                      <td>&nbsp;650</td>
                      <td>&nbsp;425</td>
                      <td>&nbsp;370</td>
                      <td>&nbsp;335</td>
                      <td>&nbsp;348</td>
                      <td>205</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 300</td>
                      <td>&nbsp;750</td>
                      <td>&nbsp;485</td>
                      <td>&nbsp;430</td>
                      <td>&nbsp;390</td>
                      <td>&nbsp;390</td>
                      <td>310</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 350</td>
                      <td>&nbsp;850</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;490</td>
                      <td>&nbsp;450</td>
                      <td>&nbsp;420</td>
                      <td>420</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 400</td>
                      <td>&nbsp;950</td>
                      <td>&nbsp;610</td>
                      <td>&nbsp;550</td>
                      <td>&nbsp;505</td>
                      <td>&nbsp;468</td>
                      <td>540</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 500</td>
                      <td>&nbsp;1150</td>
                      <td>&nbsp;730</td>
                      <td>&nbsp;660</td>
                      <td>&nbsp;615</td>
                      <td>&nbsp;550</td>
                      <td>905</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 600</td>
                      <td>&nbsp;1350</td>
                      <td>&nbsp;840</td>
                      <td>&nbsp;770</td>
                      <td>&nbsp;720</td>
                      <td>&nbsp;620</td>
                      <td>1320</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 700</td>
                      <td>&nbsp;1450</td>
                      <td>&nbsp;960</td>
                      <td>&nbsp;875</td>
                      <td>&nbsp;820</td>
                      <td>&nbsp;650</td>
                      <td>1800</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 800</td>
                      <td>&nbsp;1650</td>
                      <td>&nbsp;1075</td>
                      <td>&nbsp;990</td>
                      <td>&nbsp;930</td>
                      <td>&nbsp;720</td>
                      <td>2450</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж76нж Ду 1000</td>
                      <td>&nbsp;2050</td>
                      <td>&nbsp;1315</td>
                      <td>&nbsp;1210</td>
                      <td>&nbsp;1140</td>
                      <td>&nbsp;890</td>
                      <td>3860</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан обратный поворотный (затвор обратный) PN 4,0 МПа",
        subspecies: [
          {
            subspeciesLabel: "19с53нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19с53нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19с53нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19с53нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -40 до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19с53нж
                </h3>
                <ol>
                  <li>Корпус: углеродистая сталь 25Л </li>
                  <li>Седло: 25Л+20Х13</li>
                  <li>Диск: 25Л+20Х13</li>
                  <li>Скоба: 25Л</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 20Х13</li>
                  <li>Кронштейн: 25Л</li>
                  <li>Гайка: сталь45</li>
                  <li>Шпилька: сталь 35</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 25Л</li>
                  <li>Рым-болт: сталь 45</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных 19с53нж
                  (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 50</td>
                      <td>230</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;87</td>
                      <td>135</td>
                      <td>&nbsp;14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 65</td>
                      <td>290</td>
                      <td>180</td>
                      <td>&nbsp;145</td>
                      <td>&nbsp;109</td>
                      <td>142</td>
                      <td>&nbsp;18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 80</td>
                      <td>310</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;120</td>
                      <td>165</td>
                      <td>&nbsp;24</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 100</td>
                      <td>350</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;149</td>
                      <td>180</td>
                      <td>&nbsp;36</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 125</td>
                      <td>400</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;175</td>
                      <td>210</td>
                      <td>&nbsp;63</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 150</td>
                      <td>480</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;203</td>
                      <td>233</td>
                      <td>&nbsp;80</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 200</td>
                      <td>550</td>
                      <td>375</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;259</td>
                      <td>304</td>
                      <td>&nbsp;145</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 250</td>
                      <td>650</td>
                      <td>445</td>
                      <td>&nbsp;385</td>
                      <td>&nbsp;312</td>
                      <td>348</td>
                      <td>&nbsp;280</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 300</td>
                      <td>750</td>
                      <td>510</td>
                      <td>&nbsp;450</td>
                      <td>&nbsp;363</td>
                      <td>390</td>
                      <td>&nbsp;390</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 350</td>
                      <td>850</td>
                      <td>570</td>
                      <td>&nbsp;510</td>
                      <td>&nbsp;421</td>
                      <td>420</td>
                      <td>&nbsp;530</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 400</td>
                      <td>950</td>
                      <td>655</td>
                      <td>&nbsp;585</td>
                      <td>&nbsp;473</td>
                      <td>468</td>
                      <td>&nbsp;635</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 500</td>
                      <td>1150</td>
                      <td>755</td>
                      <td>&nbsp;670</td>
                      <td>&nbsp;575</td>
                      <td>550</td>
                      <td>&nbsp;1150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 600</td>
                      <td>1350</td>
                      <td>890</td>
                      <td>&nbsp;795</td>
                      <td>&nbsp;677</td>
                      <td>620</td>
                      <td>&nbsp;1530</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 700</td>
                      <td>1450</td>
                      <td>995</td>
                      <td>&nbsp;900</td>
                      <td>&nbsp;777</td>
                      <td>650</td>
                      <td>&nbsp;2150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с53нж Ду 800</td>
                      <td>1650</td>
                      <td>1135</td>
                      <td>&nbsp;1030</td>
                      <td>&nbsp;877</td>
                      <td>720</td>
                      <td>&nbsp;2880</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19лс53нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19лс53нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19лс53нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19лс53нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19лс53нж
                </h3>
                <ol>
                  <li>Корпус: 20ГЛ</li>
                  <li>Седло: 20ГЛ+08Х18Н10</li>
                  <li>Диск: 20ГЛ+08Х18Н10</li>
                  <li>Скоба: 20ГЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: 20ГЛ</li>
                  <li>Гайка: 25ХМ</li>
                  <li>Шпилька: 42ХМ</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Рым-болт: 42ХМ</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19лс53нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 50</td>
                      <td>230</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;87</td>
                      <td>135</td>
                      <td>&nbsp;14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 65</td>
                      <td>290</td>
                      <td>180</td>
                      <td>&nbsp;142</td>
                      <td>&nbsp;109</td>
                      <td>145</td>
                      <td>&nbsp;18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 80</td>
                      <td>310</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;120</td>
                      <td>165</td>
                      <td>&nbsp;24</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 100</td>
                      <td>350</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;149</td>
                      <td>180</td>
                      <td>&nbsp;36</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 125</td>
                      <td>400</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;175</td>
                      <td>210</td>
                      <td>&nbsp;63</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 150</td>
                      <td>480</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;203</td>
                      <td>233</td>
                      <td>&nbsp;80</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 200</td>
                      <td>550</td>
                      <td>375</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;259</td>
                      <td>304</td>
                      <td>&nbsp;145</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 250</td>
                      <td>650</td>
                      <td>445</td>
                      <td>&nbsp;385</td>
                      <td>&nbsp;312</td>
                      <td>348</td>
                      <td>&nbsp;280</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 300</td>
                      <td>750</td>
                      <td>510</td>
                      <td>&nbsp;450</td>
                      <td>&nbsp;363</td>
                      <td>390</td>
                      <td>&nbsp;390</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 350</td>
                      <td>850</td>
                      <td>570</td>
                      <td>&nbsp;510</td>
                      <td>&nbsp;421</td>
                      <td>420</td>
                      <td>&nbsp;530</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 400</td>
                      <td>950</td>
                      <td>655</td>
                      <td>&nbsp;585</td>
                      <td>&nbsp;473</td>
                      <td>468</td>
                      <td>&nbsp;635</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 500</td>
                      <td>1150</td>
                      <td>755</td>
                      <td>&nbsp;670</td>
                      <td>&nbsp;575</td>
                      <td>550</td>
                      <td>&nbsp;1150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 600</td>
                      <td>1350</td>
                      <td>890</td>
                      <td>&nbsp;795</td>
                      <td>&nbsp;677</td>
                      <td>620</td>
                      <td>&nbsp;1530</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 700</td>
                      <td>1450</td>
                      <td>995</td>
                      <td>&nbsp;900</td>
                      <td>&nbsp;777</td>
                      <td>650</td>
                      <td>&nbsp;2150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс53нж Ду 800</td>
                      <td>1850</td>
                      <td>1135</td>
                      <td>&nbsp;1030</td>
                      <td>&nbsp;877</td>
                      <td>720</td>
                      <td>&nbsp;2880</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19нж53нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19нж53нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19нж53нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19нж53нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19нж53нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ</li>
                  <li>Седло: 12Х18Н9ТЛ</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Скоба: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: сталь 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Рым-болт: 08Х18Н10</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19нж53нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 50</td>
                      <td>230</td>
                      <td>160</td>
                      <td>&nbsp;125</td>
                      <td>&nbsp;87</td>
                      <td>135</td>
                      <td>&nbsp;14</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 65</td>
                      <td>290</td>
                      <td>180</td>
                      <td>&nbsp;142</td>
                      <td>&nbsp;109</td>
                      <td>145</td>
                      <td>&nbsp;18</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 80</td>
                      <td>310</td>
                      <td>195</td>
                      <td>&nbsp;160</td>
                      <td>&nbsp;120</td>
                      <td>165</td>
                      <td>&nbsp;24</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 100</td>
                      <td>350</td>
                      <td>230</td>
                      <td>&nbsp;190</td>
                      <td>&nbsp;149</td>
                      <td>180</td>
                      <td>&nbsp;36</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 125</td>
                      <td>400</td>
                      <td>270</td>
                      <td>&nbsp;220</td>
                      <td>&nbsp;175</td>
                      <td>210</td>
                      <td>&nbsp;63</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 150</td>
                      <td>480</td>
                      <td>300</td>
                      <td>&nbsp;250</td>
                      <td>&nbsp;203</td>
                      <td>233</td>
                      <td>&nbsp;80</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 200</td>
                      <td>550</td>
                      <td>375</td>
                      <td>&nbsp;320</td>
                      <td>&nbsp;259</td>
                      <td>304</td>
                      <td>&nbsp;145</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 250</td>
                      <td>650</td>
                      <td>445</td>
                      <td>&nbsp;385</td>
                      <td>&nbsp;312</td>
                      <td>348</td>
                      <td>&nbsp;280</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 300</td>
                      <td>750</td>
                      <td>510</td>
                      <td>&nbsp;450</td>
                      <td>&nbsp;363</td>
                      <td>390</td>
                      <td>&nbsp;390</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 350</td>
                      <td>850</td>
                      <td>570</td>
                      <td>&nbsp;510</td>
                      <td>&nbsp;421</td>
                      <td>420</td>
                      <td>&nbsp;530</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 400</td>
                      <td>950</td>
                      <td>655</td>
                      <td>&nbsp;585</td>
                      <td>&nbsp;473</td>
                      <td>468</td>
                      <td>&nbsp;635</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 500</td>
                      <td>1150</td>
                      <td>755</td>
                      <td>&nbsp;670</td>
                      <td>&nbsp;575</td>
                      <td>550</td>
                      <td>&nbsp;1150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 600</td>
                      <td>1350</td>
                      <td>890</td>
                      <td>&nbsp;795</td>
                      <td>&nbsp;677</td>
                      <td>620</td>
                      <td>&nbsp;1530</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 700</td>
                      <td>1450</td>
                      <td>995</td>
                      <td>&nbsp;900</td>
                      <td>&nbsp;777</td>
                      <td>650</td>
                      <td>&nbsp;2150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж53нж Ду 800</td>
                      <td>1850</td>
                      <td>1135</td>
                      <td>&nbsp;1030</td>
                      <td>&nbsp;877</td>
                      <td>720</td>
                      <td>&nbsp;2880</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
      {
        label: "Клапан обратный поворотный (затвор обратный) PN 6,3 МПа",
        subspecies: [
          {
            subspeciesLabel: "19c38нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19c38нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19c38нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19c38нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -40 до +450°С</p>
                <p>Условия эксплуатации: У1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19c38нж
                </h3>
                <ol>
                  <li>Корпус: углеродистая сталь 25Л ГОСТ 977-88</li>
                  <li>Седло: 25Л+20Х13</li>
                  <li>Диск: 25Л+20Х13</li>
                  <li>Скоба: 25Л</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 20Х13</li>
                  <li>Кронштейн: 25Л</li>
                  <li>Гайка: сталь45</li>
                  <li>Шпилька: сталь 35</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 25Л</li>
                  <li>Рым-болт: сталь 45</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных 19c38нж
                  (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 50</td>
                      <td>300</td>
                      <td>175</td>
                      <td>135</td>
                      <td>102</td>
                      <td>184</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 65</td>
                      <td>340</td>
                      <td>200</td>
                      <td>160</td>
                      <td>132</td>
                      <td>210</td>
                      <td>41</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 80</td>
                      <td>380</td>
                      <td>210</td>
                      <td>170</td>
                      <td>133</td>
                      <td>232</td>
                      <td>49</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 100</td>
                      <td>430</td>
                      <td>250</td>
                      <td>200</td>
                      <td>170</td>
                      <td>263</td>
                      <td>80</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 125</td>
                      <td>500</td>
                      <td>295</td>
                      <td>240</td>
                      <td>205</td>
                      <td>295</td>
                      <td>110</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 150</td>
                      <td>550</td>
                      <td>340</td>
                      <td>280</td>
                      <td>240</td>
                      <td>374</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 200</td>
                      <td>650</td>
                      <td>405</td>
                      <td>345</td>
                      <td>285</td>
                      <td>426</td>
                      <td>275</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 250</td>
                      <td>775</td>
                      <td>470</td>
                      <td>400</td>
                      <td>345</td>
                      <td>517</td>
                      <td>390</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 300</td>
                      <td>900</td>
                      <td>530</td>
                      <td>460</td>
                      <td>410</td>
                      <td>569</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 350</td>
                      <td>1025</td>
                      <td>595</td>
                      <td>525</td>
                      <td>465</td>
                      <td>622</td>
                      <td>800</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19с38нж Ду 400</td>
                      <td>1150</td>
                      <td>670</td>
                      <td>585</td>
                      <td>535</td>
                      <td>680</td>
                      <td>990</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19лс38нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19лс38нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19лс38нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19лс38нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +450°С</p>
                <p>Условия эксплуатации: ХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19лс38нж
                </h3>
                <ol>
                  <li>Корпус: 20ГЛ</li>
                  <li>Седло: 20ГЛ+08Х18Н10</li>
                  <li>Диск: 20ГЛ+08Х18Н10</li>
                  <li>Скоба: 20ГЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: 20ГЛ</li>
                  <li>Гайка: 25ХМ</li>
                  <li>Шпилька: 42ХМ</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 20ГЛ</li>
                  <li>Рым-болт: 42ХМ</li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19лс38нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 50</td>
                      <td>300</td>
                      <td>175</td>
                      <td>135</td>
                      <td>102</td>
                      <td>184</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 65</td>
                      <td>340</td>
                      <td>200</td>
                      <td>160</td>
                      <td>132</td>
                      <td>210</td>
                      <td>41</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 80</td>
                      <td>380</td>
                      <td>210</td>
                      <td>170</td>
                      <td>133</td>
                      <td>232</td>
                      <td>49</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 100</td>
                      <td>430</td>
                      <td>250</td>
                      <td>200</td>
                      <td>170</td>
                      <td>263</td>
                      <td>80</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 125</td>
                      <td>500</td>
                      <td>295</td>
                      <td>240</td>
                      <td>205</td>
                      <td>295</td>
                      <td>110</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 150</td>
                      <td>550</td>
                      <td>340</td>
                      <td>280</td>
                      <td>240</td>
                      <td>374</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 200</td>
                      <td>650</td>
                      <td>405</td>
                      <td>345</td>
                      <td>285</td>
                      <td>426</td>
                      <td>275</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 250</td>
                      <td>775</td>
                      <td>470</td>
                      <td>400</td>
                      <td>345</td>
                      <td>517</td>
                      <td>390</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 300</td>
                      <td>900</td>
                      <td>530</td>
                      <td>460</td>
                      <td>410</td>
                      <td>569</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 350</td>
                      <td>1025</td>
                      <td>595</td>
                      <td>525</td>
                      <td>465</td>
                      <td>622</td>
                      <td>800</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19лс38нж Ду 400</td>
                      <td>1150</td>
                      <td>670</td>
                      <td>585</td>
                      <td>535</td>
                      <td>680</td>
                      <td>990</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
          {
            subspeciesLabel: "19нж38нж",
            subspeciesContent: (
              <div className={styles.productInfoContentLeft}>
                <h2 className={styles.productInfoTextH2}>19нж38нж</h2>
                <h3 className={styles.productInfoText}>
                  Технические характеристики клапанов обратных поворотных
                  стальных 19нж38нж
                </h3>
                <br />
                <h3 className={styles.productInfoText}>
                  Показатели назначения клапанов обратных поворотных стальных
                  19нж38нж
                </h3>
                <br />
                <p>
                  Рабочая среда: Вода, пар, масло, нефть, неагрессивные жидкие
                  нефтепродукты, жидкие и газообразные среды, и другие среды по
                  отношению к которым материалы, применяемые в клапанах,
                  коррозионостойкие
                </p>

                <p>Температура рабочей среды: от -60 до +600°С</p>
                <p>Условия эксплуатации: УХЛ1</p>
                <br />
                <h3 className={styles.productInfoText}>
                  Материальное исполнение клапанов обратных поворотных стальных
                  19нж38нж
                </h3>
                <ol>
                  <li>Корпус: 12Х18Н9ТЛ</li>
                  <li>Седло: 12Х18Н9ТЛ</li>
                  <li>Диск: 12Х18Н9ТЛ</li>
                  <li>Скоба: 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Палец: 08Х18Н10</li>
                  <li>Кронштейн: сталь 12Х18Н9ТЛ</li>
                  <li>Гайка: 08Х18Н10</li>
                  <li>Шпилька: 08Х18Н10</li>
                  <li>Болт: 08Х18Н10</li>
                  <li>Прокладка: 08Х18Н10+графит</li>
                  <li>Крышка: 12Х18Н9ТЛ</li>
                  <li>Рым-болт: 08Х18Н10 </li>
                </ol>
                <br />
                <h3 className={styles.productInfoText}>
                  Габаритные размеры клапанов обратных поворотных стальных
                  19нж38нж (фланец)
                </h3>
                <table className={styles.iksweb}>
                  <tbody>
                    <tr>
                      <th>
                        <strong>
                          <span>Наименование</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>L</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D1</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>D2</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>H</span>
                        </strong>
                      </th>
                      <th>
                        <strong>
                          <span>Масса, кг</span>
                        </strong>
                      </th>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж Ду 50</td>
                      <td>300</td>
                      <td>175</td>
                      <td>135</td>
                      <td>102</td>
                      <td>184</td>
                      <td>32</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 65</td>
                      <td>340</td>
                      <td>200</td>
                      <td>160</td>
                      <td>132</td>
                      <td>210</td>
                      <td>41</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 80</td>
                      <td>380</td>
                      <td>210</td>
                      <td>170</td>
                      <td>133</td>
                      <td>232</td>
                      <td>49</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 100</td>
                      <td>430</td>
                      <td>250</td>
                      <td>200</td>
                      <td>170</td>
                      <td>263</td>
                      <td>80</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 125</td>
                      <td>500</td>
                      <td>295</td>
                      <td>240</td>
                      <td>205</td>
                      <td>295</td>
                      <td>110</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 150</td>
                      <td>550</td>
                      <td>340</td>
                      <td>280</td>
                      <td>240</td>
                      <td>374</td>
                      <td>150</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 200</td>
                      <td>650</td>
                      <td>405</td>
                      <td>345</td>
                      <td>285</td>
                      <td>426</td>
                      <td>275</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 250</td>
                      <td>775</td>
                      <td>470</td>
                      <td>400</td>
                      <td>345</td>
                      <td>517</td>
                      <td>390</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 300</td>
                      <td>900</td>
                      <td>530</td>
                      <td>460</td>
                      <td>410</td>
                      <td>569</td>
                      <td>600</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 350</td>
                      <td>1025</td>
                      <td>595</td>
                      <td>525</td>
                      <td>465</td>
                      <td>622</td>
                      <td>800</td>
                    </tr>
                    <tr>
                      <td>Клапан (затвор) 19нж38нж&nbsp;Ду 400</td>
                      <td>1150</td>
                      <td>670</td>
                      <td>585</td>
                      <td>535</td>
                      <td>680</td>
                      <td>990</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            ),
          },
        ],
      },
    ],
  },
];

export default tabsInfoProductOtvod;
